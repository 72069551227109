// tools
import { useState, Dispatch, SetStateAction } from "react";
import NumberOnly from "../../tools/numberOnly";
import User from "../../tools/userInfo";
import axios from "axios";
import Swal from "sweetalert2";

// ui
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import createAxiosInstance from "../../api/axiosConfig";

interface ModalContent {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

interface Info {
  [key: string | number]: string | number | null | undefined;
  address?: string;
  birth?: string;
  cash?: number;
  city?: string;
  country?: string;
  createdAt?: string;
  email?: string;
  firstName?: string;
  gender?: string;
  lastName?: string;
  level?: number;
  marketing?: string;
  notify?: string;
  number?: null | string | number;
  phone?: string;
  unit?: null | string;
  cart?: number;
}

const PersonalInfo = ({ setOpen }: ModalContent) => {
  const { api, token } = User();
  const [info, setInfo] = useState<Info>(User().info);
  const axiosInstance = createAxiosInstance(api, token);

  const handleData = (key: string, value: string) => {
    if (key === "number" || key === "phone") {
      setInfo((prev) => ({ ...prev, [key]: NumberOnly(value) }));
    } else {
      setInfo((prev) => ({ ...prev, [key]: value }));
    }
  };

  const original = User().info;

  const notificationAxios = () => {
    const originalValues = Object.values(original);
    const infoValues = Object.values(info);
    const keys = Object.keys(info);

    const indexs = originalValues
      .map((item, index) => {
        if (!infoValues.includes(item)) return index;
        else return -1;
      })
      .filter((idx) => idx !== -1);

    const revised: Info = {};

    for (let i = 0; i < indexs.length; i++) {
      const index = indexs[i];
      revised[keys[index]] = infoValues[index];
    }

    const dataKey = Object.keys(revised);

    const emptyFirst = dataKey.includes("firstName") && !revised.firstName;
    const emptyLast = dataKey.includes("lastName") && !revised.lastName;
    const emptyPhone = dataKey.includes("phone") && !revised.phone;

    if (emptyFirst || emptyLast || emptyPhone) {
      Swal.fire({
        title: "Error",
        text: "There is an empty value on a required field.",
        icon: "error",
        allowOutsideClick: false,
      });
      setOpen(false);
    } else {
      axiosInstance
        .put(`/auth/update`, revised)
        .then(({ data }) => {
          if (data.message) {
            Swal.fire({
              title: "Update Completed",
              icon: "success",
              allowOutsideClick: false,
              didClose: () => location.reload(),
            });
            setOpen(false);
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            title: "Error",
            text: response.data.message,
            icon: "error",
            allowOutsideClick: false,
          });
          setOpen(false);
        });
    }
  };

  return (
    <>
      <div className="modal-tit-wrap">
        <h3 className="tit">Personal Information Edit</h3>
        <span
          className="btn material-symbols-rounded"
          onClick={() => setOpen(false)}
        >
          close
        </span>
      </div>

      <div className="modal-cont-wrap">
        <form>
          <div className="input-wrap">
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              value={info.firstName}
              onChange={({ target }) => handleData("firstName", target.value)}
            />
          </div>
          <div className="input-wrap">
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              value={info.lastName}
              onChange={({ target }) => handleData("lastName", target.value)}
            />
          </div>
          <div className="input-wrap">
            <TextField
              label="Phone Number"
              variant="outlined"
              fullWidth
              value={info.phone}
              onChange={({ target }) => handleData("phone", target.value)}
            />
          </div>
          <div className="input-wrap">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={({ target }) => handleData("gender", target.value)}
            >
              <FormControlLabel
                value="male"
                control={<Radio checked={info.gender === "male"} />}
                label="Male"
              />
              <FormControlLabel
                value="female"
                control={<Radio checked={info.gender === "female"} />}
                label="Female"
              />
              <FormControlLabel
                value="other"
                control={<Radio checked={info.gender === "other"} />}
                label="Other"
              />
            </RadioGroup>
          </div>
          <div className="input-wrap">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                slotProps={{ textField: { fullWidth: true } }}
                value={dayjs(info.birth)}
                onChange={(e: Dayjs | null) => {
                  if (e) handleData("birth", e.format("YYYYMMDD"));
                }}
              />
            </LocalizationProvider>
          </div>
          <div className="input-wrap">
            <TextField
              label="Unit/Flat"
              variant="outlined"
              sx={{ width: "50%" }}
              value={info.unit ? info.unit : ""}
              onChange={({ target }) => handleData("unit", target.value)}
            />

            <TextField
              label="Number"
              variant="outlined"
              sx={{ width: "50%" }}
              value={info.number ? info.number : ""}
              onChange={({ target }) => handleData("number", target.value)}
            />
          </div>

          <div className="input-wrap">
            <TextField
              label="Address"
              variant="outlined"
              fullWidth
              value={info.address}
              onChange={({ target }) => handleData("address", target.value)}
            />
          </div>

          <div className="input-wrap">
            <TextField
              label="City / Town or Suburb"
              variant="outlined"
              fullWidth
              value={info.city}
              onChange={({ target }) => handleData("city", target.value)}
            />
          </div>
          <div className="input-wrap">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Country
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={info.country}
                label="Select Country"
                onChange={({ target }) => handleData("country", target.value)}
              >
                <MenuItem value="AU">Australia</MenuItem>
                <MenuItem value="NZ">New Zealand</MenuItem>
              </Select>
            </FormControl>
          </div>
        </form>
      </div>
      <div className="modal-bottom-wrap">
        <div className="btn-wrap">
          <div className="btn style01 sm" onClick={() => setOpen(false)}>
            Cancel
          </div>
          <div
            className="btn style01 sm white"
            style={{ padding: "12px 36px" }}
            onClick={() => notificationAxios()}
          >
            Save
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalInfo;
