// tools
import { useState } from "react";
import { reset } from "../../tools/zustandUpdate";
import axios from "axios";
import User from "../../tools/userInfo";
import Swal from "sweetalert2";

// ui
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";

// components
import { CustModal } from "../common/commonElements";
import createAxiosInstance from "../../api/axiosConfig";

const Delete = () => {
  const { api, token, info, setToken, setTotalInfo } = User();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");

  const axiosInstance = createAxiosInstance(api, token);

  const deleteAxios = () => {
    axiosInstance
      .delete(`/auth/me`, {
        data: {
          password,
          message: "",
        },
      })
      .then(({ data }) => {
        if (data.message) {
          Swal.fire({
            title: "Sucxess",
            text: "Your account is completely deleted",
            icon: "success",
            allowOutsideClick: false,
            didClose: () => {
              reset(setTotalInfo);
              setToken("");
              location.href = "/";
            },
          });
        }
      })
      .catch(({ response }) => {
        Swal.fire({
          title: "Error",
          text: response.data.message,
          icon: "error",
          allowOutsideClick: false,
        });
      });
  };

  const deleteFunction = () => {
    const social = info.provider !== "cluster";

    if (social) {
      Swal.fire({
        title: "Do you want to close your account?",
        text: "This action cannot be recovered",
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteAxios();
        }
      });
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <div className="btn delete-id" onClick={() => deleteFunction()}>
        Delete ID
      </div>

      <CustModal open={open} setOpen={setOpen} onClose={() => setOpen(false)}>
        <div className="delete-id">
          <div className="modal-tit-wrap">
            <h3 className="tit">Delete ID</h3>
            <span
              className="btn material-symbols-rounded"
              onClick={() => setOpen(false)}
            >
              close
            </span>
          </div>

          {/* step01 - Check Password */}
          <div className="modal-cont-wrap">
            <h4 className="tit">Please Check Your Password</h4>
            <form>
              <FormControl>
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={show ? "text" : "password"}
                  value={password}
                  onChange={({ target }) => setPassword(target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShow((prev) => !prev)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {show ? (
                          <span className="material-symbols-rounded">
                            visibility
                          </span>
                        ) : (
                          <span className="material-symbols-rounded">
                            visibility_off
                          </span>
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </form>
          </div>

          {/* step02 - Check Reason */}
          {/* <div className="cont">
            <h4 className="f24">Reason for deleting your ID</h4>
            <p className="f16 exp">
              Warning! Your account will be closed. Your winnings will be paid
              out to you, however we are not permitted to pay out any unspent
              deposited funds. That portion of your balance will be paid to the
              Government Regulator for their determination, unless you spend the
              funds prior to closing your account.
            </p>
            <FormControl>
              <RadioGroup name="radio-buttons-group">
                <FormControlLabel
                  value="I'm getting too many emails"
                  control={<Radio />}
                  label="I'm getting too many emails"
                />
                <FormControlLabel
                  value="I would like to reduce my spending"
                  control={<Radio />}
                  label="I would like to reduce my spending"
                />
                <FormControlLabel
                  value="I'm no longer interested in lottery"
                  control={<Radio />}
                  label="I'm no longer interested in lottery"
                />
                <FormControlLabel
                  value="gambling problem"
                  control={<Radio />}
                  label="I have a gambling problem"
                />
                <FormControlLabel
                  value="My family doesn't want me to play"
                  control={<Radio />}
                  label="My family doesn't want me to play"
                />
                <FormControlLabel
                  value="inconvenient"
                  control={<Radio />}
                  label="It is inconvenient for me to use this service"
                />
              </RadioGroup>
            </FormControl>
          </div> */}
          <div className="modal-bottom-wrap">
            <div className="btn-wrap">
              <div
                className="btn style01 sm outline"
                onClick={() => setOpen(false)}
              >
                Cancel
              </div>
              <div className="btn style01 sm" onClick={() => deleteAxios()}>
                Delete
              </div>
            </div>
          </div>
        </div>
      </CustModal>
    </>
  );
};

export default Delete;
