// tools
import { useEffect } from "react";
import { update } from "../tools/zustandUpdate";
import axios from "axios";
import Swal from "sweetalert2";
import User from "../tools/userInfo";
import CircularProgress from "@mui/material/CircularProgress";
import createAxiosInstance from "../api/axiosConfig";

const Google = () => {
  const { api, setToken, setTotalInfo } = User();
  const query = location.search.split("?")[1].split("&");
  const state = query.filter((x) => x.includes("state="))[0].split("=")[1];
  const code = query.filter((x) => x.includes("code="))[0].split("=")[1];

  const axiosInstance = createAxiosInstance(api, "");

  const googleAxios = () => {
    axiosInstance
      .post(`/login/google`, {
        code,
        redirect: location.origin,
      })
      .then(({ data }) => {
        if (data.message === "success") {
          setToken(data.data.token);

          Swal.fire({
            title: "Login Success",
            icon: "success",
            allowOutsideClick: false,
            didClose: () => {
              update(api, data.data.token, setTotalInfo);

              const arrival = decodeURIComponent(state);

              if (arrival === "/welcome") {
                location.replace("/");
              } else {
                location.replace(arrival);
              }
            },
          });
        }
      })
      .catch(({ response }) => {
        Swal.fire({
          title: response.data.message,
          icon: "error",
          allowOutsideClick: false,
        });
      });
  };

  const googleCheckAxios = () => {
    axiosInstance
      .post(`/registration/check`, {
        provider: "google",
        code,
        redirect: location.origin,
      })
      .then(({ data }) => {
        const socialInfo = {
          email: data.data.email,
          provider: "google",
        };

        sessionStorage.setItem("social", JSON.stringify(socialInfo));

        location.replace(decodeURIComponent(state));
      })
      .catch(({ response }) =>
        Swal.fire({
          title: response.data.message,
          icon: "error",
          allowOutsideClick: false,
          didClose: () => location.replace("/signup"),
        })
      );
  };

  useEffect(() => {
    if (state.includes("signup")) {
      googleCheckAxios();
    } else {
      googleAxios();
    }
  }, []);

  return (
    <div className="sns-loading">
      <div className="loading-wrap">
        <CircularProgress />
        <p>Loading ...</p>
      </div>
    </div>
  );
};

export default Google;
