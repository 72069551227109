// tools
import { useLotto } from "../../App";
import amountMaker from "../../tools/amountMaker";

// ui
import { Skeleton } from "@mui/material";

// comp
import CountDown from "../common/countdown";
import BallSection from "./ballSection";

// img
import PowerBlurBg from "../../img/main/power-blur-bg.svg";
import MegaBlurBg from "../../img/main/mega-blur-bg.svg";
// import PowerWhiteLogo from "../../img/common/power-white-logo.svg";
// import MegaWhiteLogo from "../../img/common/mega-white-logo.svg";
import PowerWhiteLogo from "../../img/common/USA_Power_lottery.png";
import MegaWhiteLogo from "../../img/common/USA_Mega_lottery.png";
import TestPrizeStatBar from "./testPrizeStat";
import { useEffect } from "react";
import axios from "axios";
import User from "../../tools/userInfo";

const Game = () => {
  const { loading, lottery } = useLotto();
  const { api } = User();

  return (
    <section className="game-area">
      {lottery.map((item) => {
        const type =
          item.type === "MM" ? "mega" : item.type === "PB" ? "power" : "";
        const reward = amountMaker(item.jackpot * Number(item.exchange));

        return (
          <div className={`item ${type}`} key={item.type}>
            <div className="info-area">
              <img
                src={`${type === "power" ? PowerBlurBg : MegaBlurBg}`}
                alt="bg-01"
                className="bg01"
              />
              <img
                src={`${type === "power" ? PowerBlurBg : MegaBlurBg}`}
                alt="bg-02"
                className="bg02"
              />

              <div className="info-wrap">
                <img
                  src={`${type === "power" ? PowerWhiteLogo : MegaWhiteLogo}`}
                  alt="logo"
                  className="logo"
                />
                <div className="txt-wrap">
                  {loading ? (
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      style={{
                        width: "90%",
                        height: 60,
                        margin: "0 auto",
                      }}
                    />
                  ) : (
                    <p className="prize">
                      {item.isDrawdate ? (
                        ""
                      ) : (
                        <span className="unit">{reward.mark} </span>
                      )}
                      <span className="num">
                        {item.isDrawdate ? "Pending" : `${reward.amount}`}
                      </span>
                      <span className="unit">
                        {item.isDrawdate ? "" : reward.unit}
                      </span>
                    </p>
                  )}

                  <p className="time">
                    <CountDown
                      date={item.auOrderTime}
                      pending={item.isDrawdate}
                    />
                  </p>
                </div>
              </div>
              <div className="btn" onClick={() => (location.href = `/${type}`)}>
                <span>PLAY NOW</span>
              </div>
            </div>
            {/* results */}
            <BallSection type={type} original={item.type} loading={loading} />
            {/* <TestPrizeStatBar type={type} /> */}
          </div>
        );
      })}
    </section>
  );
};

export default Game;
