// tools
import { useState } from "react";
import { parsePrice } from "../../tools/parsePrice";

// img
import Thumb01 from "../../img/sub/article-thumb01.png";
import Thumb02 from "../../img/sub/article-thumb02.png";
import Thumb03 from "../../img/sub/article-thumb03.png";
import Thumb04 from "../../img/sub/article-thumb04.png";

// comp
import Article from "./article";

const images = [Thumb01, Thumb02, Thumb03, Thumb04];
const pagiTest = [
  "https://cdn.pixabay.com/photo/2018/01/18/19/06/time-3091031_1280.jpg",
  "https://cdn.pixabay.com/photo/2013/12/29/21/15/watch-235411_1280.jpg",
  "https://cdn.pixabay.com/photo/2022/04/01/03/20/timepiece-7103973_1280.jpg",
  "https://cdn.pixabay.com/photo/2020/11/24/11/55/watch-5772317_1280.jpg",
  "https://cdn.pixabay.com/photo/2014/07/15/20/09/wristwatch-394204_1280.jpg",
  "https://cdn.pixabay.com/photo/2022/03/16/13/54/watch-7072553_1280.jpg",
  "https://cdn.pixabay.com/photo/2020/06/28/18/59/wrist-watch-5350376_1280.jpg",
  "https://cdn.pixabay.com/photo/2021/10/21/19/01/wristwatch-6730089_1280.jpg",
  "https://cdn.pixabay.com/photo/2018/01/18/19/06/time-3091031_1280.jpg",
  "https://cdn.pixabay.com/photo/2013/12/29/21/15/watch-235411_1280.jpg",
  "https://cdn.pixabay.com/photo/2022/04/01/03/20/timepiece-7103973_1280.jpg",
  "https://cdn.pixabay.com/photo/2020/11/24/11/55/watch-5772317_1280.jpg",
  "https://cdn.pixabay.com/photo/2014/07/15/20/09/wristwatch-394204_1280.jpg",
  "https://cdn.pixabay.com/photo/2022/03/16/13/54/watch-7072553_1280.jpg",
  "https://cdn.pixabay.com/photo/2020/06/28/18/59/wrist-watch-5350376_1280.jpg",
  "https://cdn.pixabay.com/photo/2021/10/21/19/01/wristwatch-6730089_1280.jpg",
  "https://cdn.pixabay.com/photo/2018/01/18/19/06/time-3091031_1280.jpg",
  "https://cdn.pixabay.com/photo/2013/12/29/21/15/watch-235411_1280.jpg",
  "https://cdn.pixabay.com/photo/2022/04/01/03/20/timepiece-7103973_1280.jpg",
  "https://cdn.pixabay.com/photo/2020/11/24/11/55/watch-5772317_1280.jpg",
  "https://cdn.pixabay.com/photo/2014/07/15/20/09/wristwatch-394204_1280.jpg",
  "https://cdn.pixabay.com/photo/2022/03/16/13/54/watch-7072553_1280.jpg",
  "https://cdn.pixabay.com/photo/2020/06/28/18/59/wrist-watch-5350376_1280.jpg",
  "https://cdn.pixabay.com/photo/2021/10/21/19/01/wristwatch-6730089_1280.jpg",
  "https://cdn.pixabay.com/photo/2018/01/18/19/06/time-3091031_1280.jpg",
  "https://cdn.pixabay.com/photo/2018/01/18/19/06/time-3091031_1280.jpg",
  "https://cdn.pixabay.com/photo/2018/01/18/19/06/time-3091031_1280.jpg",
  "https://cdn.pixabay.com/photo/2018/01/18/19/06/time-3091031_1280.jpg",
  "https://cdn.pixabay.com/photo/2013/12/29/21/15/watch-235411_1280.jpg",
  "https://cdn.pixabay.com/photo/2013/12/29/21/15/watch-235411_1280.jpg",
];

interface StorePrice {
  store: string;
  price: string;
}

interface DummyProps {
  thumb: string;
  title: string;
  cate: string;
  id: number;
  compare: StorePrice[];
}

const dummy: DummyProps[] = [
  {
    id: 1,
    thumb: Thumb01,
    title: "필립 파텍 A",
    cate: "watch",
    compare: [
      {
        store: "로랜텍",
        price: "25,420",
      },
      {
        store: "COUPANG",
        price: "22,220",
      },
      {
        store: "애플PIG",
        price: "24,530",
      },
    ],
  },
  {
    id: 2,
    thumb: Thumb03,
    title: "필립 파텍 B",
    cate: "watch",
    compare: [
      {
        store: "로랜텍",
        price: "25,420",
      },
      {
        store: "애플PIG",
        price: "25,530",
      },
      {
        store: "COUPANG",
        price: "33,420",
      },
      {
        store: "TAEMU",
        price: "15,420",
      },
      {
        store: "WISH",
        price: "19,340",
      },
      {
        store: "Yahoo",
        price: "33,150",
      },
      {
        store: "Naver",
        price: "29,950",
      },
    ],
  },
];

/** compare item component */
const CompareItem: React.FC<{ item: DummyProps }> = ({ item }) => {
  const sortedItems = item.compare.sort(
    (a, b) => parsePrice(a.price) - parsePrice(b.price)
  );

  const lowestPrice = Math.min(
    ...item.compare.map((comp) => parsePrice(comp.price))
  );

  const [showAll, setShowAll] = useState<boolean>(false);

  const handleViewMore = () => {
    setShowAll(true);
  };

  return (
    <div className="item">
      <div className="product">
        <div className="img-wrap">
          <img src={item.thumb} alt="Thumbnail" />
        </div>
        <div className="tit-wrap">
          <h4 className="f18">{item.title}</h4>
          <div
            className="btn style02"
            onClick={() =>
              (location.href = `/magazine/detail?cate=${item.cate}&id=${item.id}`)
            }
          >
            <span>Details more</span>
            <span className="material-symbols-rounded">add</span>
          </div>
        </div>
      </div>
      <ul className="compare-price">
        {/* sorted of price */}
        {sortedItems
          .slice(0, showAll ? sortedItems.length : 5)
          .map((compItem, idx) => {
            const priceInt = parsePrice(compItem.price);
            return (
              <li key={idx}>
                <p className="site">{compItem.store}</p>
                <p
                  className={`price ${
                    priceInt === lowestPrice ? "lowest" : ""
                  }`}
                >
                  $ {compItem.price}
                </p>
                <div className="btn style01 sm">Shop Now</div>
              </li>
            );
          })}

        {!showAll && item.compare.length > 5 && (
          <div
            className="btn style01 sm"
            style={{ marginTop: ".5rem", width: "100%" }}
            onClick={handleViewMore}
          >
            View more +
          </div>
        )}
      </ul>
    </div>
  );
};

// Main
const Watch: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const perPage = 4;
  const totalPages = Math.ceil(pagiTest.length / perPage);

  const handlePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const currentItems = pagiTest.slice(
    (currentPage - 1) * perPage,
    currentPage * perPage
  );

  return (
    <div className="maga-watch">
      <section className="maga-section">
        <h3 className="tit">Latest Articles</h3>
        <div className="article">
          {currentItems.map((image, index) => (
            <Article image={image} index={index} key={index} />
          ))}
        </div>
      </section>

      <section className="maga-section">
        <h3 className="tit">Compare Price</h3>
        <div className="compare">
          {dummy.map((item) => (
            <CompareItem item={item} key={item.id} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default Watch;
