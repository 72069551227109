import axios from "axios";

const createAxiosInstance = (api: string, token: string) => {
  return axios.create({
    baseURL: api,
    headers: {
      "ngrok-skip-browser-warning": "69420",
      Authorization: "bearer " + token,
    },
  });
};

export default createAxiosInstance;
