import createAxiosInstance from "../api/axiosConfig";

// tools
import type { Info } from "./userInfo";

export const update = (
  api: string,
  token: string,
  setTotalInfo: (info: Info) => void
) => {
  const axiosInstance = createAxiosInstance(api, token);

  axiosInstance
    .get(`/auth/me`)
    .then(({ data }) => {
      if (data.message) {
        setTotalInfo(data.data);
      }
    })
    .catch((response) => {});
};

export const reset = (setTotalInfo: (info: Info) => void) => {
  setTotalInfo({
    address: "",
    birth: "",
    cash: 0,
    city: "",
    country: "",
    createdAt: "",
    email: "",
    firstName: "",
    gender: "",
    lastName: "",
    level: 0,
    marketing: "",
    notify: "",
    number: "",
    phone: "",
    unit: "",
    provider: "",
    prize: 0,
    totalPrize: 0,
    weeklySpendLimit: 0,
    remaining: 0,
    cart: 0,
  });
};
