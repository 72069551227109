// tools
import { useEffect, useState } from "react";
import { SyndicateInfo } from "../../pages/syndicates";
import Countdown, { CountdownRenderProps } from "react-countdown";
import amountMaker from "../../tools/amountMaker";

// img
import Mega from "../../img/common/USA_Mega_lottery.png";
import Power from "../../img/common/USA_Power_lottery.png";

// layout
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import User from "../../tools/userInfo";
import Swal from "sweetalert2";
import { update } from "../../tools/zustandUpdate";
import { useLotto } from "../../App";
import createAxiosInstance from "../../api/axiosConfig";

// type
interface SynItemProps {
  data: SyndicateInfo;
  getSynData: () => void;
}

const SynItem = ({ data, getSynData }: SynItemProps) => {
  // provider
  const { limitCart, lottery, checkNoLoginCart, noLoginCart } = useLotto();

  // stat
  const [numOpen, setNumOpen] = useState<boolean>(false);
  const [selectTicket, setSelectTicket] = useState<number>(1);
  const [exChange, setExChange] = useState<string>("1");
  const { info, api, token, setTotalInfo } = User();
  const axiosInstance = createAxiosInstance(api, token);

  // cons
  const parseDate = new Date(data.auDrawTime);
  const remain = data.ticketIssued - data.ticketSold;

  // noLogin Cart
  const existingData = localStorage.getItem("noLgCart");
  const noLgCartArray = existingData ? JSON.parse(existingData) : [];

  // func
  const isGameType = (type: string) => {
    if (type === "PB") {
      return "power";
    } else {
      return "mega";
    }
  };

  const isType2 = (type: string) => {
    const lotteryType = type === "mega" ? "MM" : "PB";
    return lottery.find((lot) => lot.type === lotteryType);
  };

  useEffect(() => {
    // const exchange =
    //   data.productCode === "MM" ? lottery[0]?.exchange : lottery[1]?.exchange;

    const exchange = isType2(data.productCode)?.exchange;

    setExChange(String(exchange));
  }, [exChange, lottery]);

  // api
  const addSynCart = (synItem: SyndicateInfo, qty: number) => {
    const noLgCart = JSON.parse(localStorage.getItem("noLgCart") || "[]");
    const existingItem = noLgCart.find((item: any) => item.idx === synItem.idx);

    console.log(noLgCart);
    console.log(existingItem);

    if (token.length === 0) {
      if (noLoginCart >= limitCart) {
        {
          Swal.fire({
            icon: "warning",
            title: "You cannot purchase more than 20 games in the cart.",
            confirmButtonColor: "#af7af1",
            cancelButtonColor: "#876aab",
          });
        }
      } else if (existingItem) {
        if (existingItem.ticketIssued < existingItem.ticketQty + qty) {
          Swal.fire({
            icon: "warning",
            title: "Cannot exceed purchase limit.",
            allowOutsideClick: false,
            confirmButtonColor: "#af7af1",
            cancelButtonColor: "#876aab",
          });
        } else {
          existingItem.ticketQty += qty;
          localStorage.setItem("noLgCart", JSON.stringify(noLgCart));

          Swal.fire({
            icon: "success",
            title: "Completed Add to Cart!",
            allowOutsideClick: false,
            confirmButtonColor: "#af7af1",
            cancelButtonColor: "#876aab",
          });
        }
      } else {
        const type = data.productCode === "PB" ? 0 : 1;
        const date = lottery[type].playdate;

        const noLgData = {
          idx: data.idx,
          productCode: data.productCode,
          productName: data.name,
          methodCode: "SYN",
          systemCode: null,
          syndicateIdx: data.idx,
          multiplier: data.multiplier,
          draws: 1,
          ticketIssued: data.ticketIssued,
          ticketQty: selectTicket,
          subscription: "N",
          totalAmount: Number(data.price) * selectTicket,
          jackpot: data.jackpot,
          playdate: date,
          nums: data.nums,
          auOrderTime: data.auDrawTime,
          unitPrice: data.price,
        };

        noLgCartArray.push(noLgData);
        localStorage.setItem("noLgCart", JSON.stringify(noLgCartArray));

        Swal.fire({
          icon: "success",
          title: "Completed Add to Cart!",
          allowOutsideClick: false,
          confirmButtonColor: "#af7af1",
          cancelButtonColor: "#876aab",
          didClose: () => {
            getSynData();
            checkNoLoginCart();
          },
        });

        return;
      }
    } else {
      const data = {
        idx: synItem.idx,
        ticketQty: qty,
        amount: selectTicket * Number(synItem.price),
      };

      const dataArray = [];
      dataArray.push(data);

      if (info.cart >= limitCart) {
        Swal.fire({
          icon: "warning",
          title: "You cannot purchase more than 20 games in the cart.",
          confirmButtonColor: "#af7af1",
          cancelButtonColor: "#876aab",
        });
      } else {
        axiosInstance
          .post(`/cart/syndicate`, dataArray)
          .then((res) =>
            Swal.fire({
              icon: "success",
              title: "Completed Add to Cart!",
              allowOutsideClick: false,
              confirmButtonColor: "#af7af1",
              cancelButtonColor: "#876aab",
              didClose: () => {
                getSynData();

                update(api, token, setTotalInfo);
              },
            })
          )
          .catch((error) => {
            console.error(error);
            Swal.fire({
              icon: "warning",
              title: "ticket remain is changed, please retry",
              confirmButtonColor: "#af7af1",
              cancelButtonColor: "#876aab",
              didClose: () => window.location.reload(),
            });
          });
      }
    }
  };

  const reward = amountMaker(data.jackpot * Number(exChange));

  return (
    <>
      <div className="item">
        <div className="game-info-area">
          <img
            src={isGameType(data.productCode) === "power" ? Power : Mega}
            alt="Mega Millions"
          />
          <div className="item-section">
            <div className="name-tag-wrap">
              <p
                className={`name ${
                  isGameType(data.productCode) === "power" ? "power" : "mega"
                }`}
              >
                {data.name}
                {/* {isGameType(data.productCode) === "power"
                  ? "Power ball"
                  : "Mega Millions"} */}
              </p>

              {/* --- tag --- */}
              {data.multiplier === "N" && (
                <p className="tag multiplier">Multiplier</p>
              )}
              {data.systemCode === "GUA" && (
                <p className="tag guaranteed">Guaranteed</p>
              )}
              {data.systemCode?.includes("ST") && (
                <p className="tag system">System {data.systemCode}</p>
              )}
            </div>
            <p className="prize">
              <span className="unit">{reward.mark} </span>
              <span className="num">{reward.amount}</span>
              <span className="unit"> {reward.unit}</span>
            </p>

            <p className="info" style={{ marginBottom: "8px" }}>
              {data.description}
            </p>

            <p className="time">
              <Countdown
                date={parseDate}
                renderer={(props) => <CountDownView {...props} />}
                intervalDelay={60000}
              />
            </p>
          </div>
          <div className="item-section">
            <p className="remain">{remain} shares remaining</p>
            <p className="tshare">Total shares : {data.ticketIssued}</p>
            <FormControl fullWidth>
              <InputLabel id="Syndicate">Syndicate</InputLabel>
              <Select
                labelId="Syndicate"
                id="Syndicate"
                label="Syndicates"
                defaultValue={1}
              >
                {Array.from({ length: remain }, (_, index) => {
                  const value: number = index + 1;
                  const price = value * Number(data.price);
                  return (
                    <MenuItem
                      key={value}
                      value={value}
                      onClick={() => setSelectTicket(value)}
                    >
                      {value} {index === 0 ? "Share" : "Shares"} - ${" "}
                      {price.toFixed(2)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <div
              className={`btn style02 see-num ${numOpen && "open"}`}
              onClick={() => {
                setNumOpen(!numOpen);
              }}
            >
              {data.games} Games {!numOpen ? "( See numbers" : "( Hide numbers"}
              <span className="material-symbols-rounded">
                keyboard_arrow_down
              </span>
              )
            </div>
          </div>
          <div className="item-section">
            <p className="total">
              Total :
              <span>$ {(selectTicket * Number(data.price)).toFixed(2)}</span>
            </p>
            <div
              className="btn style01 md spc"
              onClick={() => addSynCart(data, selectTicket)}
            >
              Add to cart
            </div>
          </div>
        </div>

        {/* --- show nums --- */}
        <div className={`vertical-trans ${numOpen ? "open" : "close"}`}>
          <div className="show-number">
            <div className="number-wrap">
              {data.nums.map((it, idx) => (
                <div className="number" key={idx}>
                  <p className="">#{idx + 1}</p>
                  <div className="ball-wrap">
                    {it.num.split(",").map((number, idx) => (
                      <div
                        key={idx}
                        className={`ball ${
                          idx === it.num.split(",").length - 1
                            ? isGameType(data.productCode)
                            : ""
                        }`}
                      >
                        {number}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SynItem;

const CountDownView = ({ days, hours, minutes }: CountdownRenderProps) => {
  const renderTime = () => {
    if (days > 0) {
      return `Close in : ${days} days, ${hours} hrs`;
    } else if (hours > 0 || minutes > 0) {
      return `Close in : ${hours} hrs, ${minutes} mins`;
    } else {
      return `Close soon`;
    }
  };

  return <p className="countdown">{renderTime()}</p>;
};
