import React, { useState } from "react";
import MyBar from "./myCharts/myBar";
import MyLine from "./myCharts/myLine";
import MyPie from "./myCharts/MyPie";

const MyStat = () => {
  const [chartOpen, setChartOpen] = useState<string>("L");

  const handleChart = (target: string) => {
    setChartOpen(target); // 상태 변경
  };

  return (
    <div className="my-stat">
      <div className="btn-wrap">
        <div
          className={`btn style01 sm ${chartOpen == "L" && "white"}`}
          onClick={() => handleChart("L")}
        >
          Line chart
        </div>
        <div
          className={`btn style01 sm ${chartOpen == "B" && "white"}`}
          onClick={() => handleChart("B")}
        >
          Bar chart
        </div>
        <div
          className={`btn style01 sm ${chartOpen == "D" && "white"}`}
          onClick={() => handleChart("D")}
        >
          Pie chart
        </div>
      </div>

      {chartOpen === "L" && <MyLine />}
      {chartOpen === "B" && <MyBar />}
      {chartOpen === "D" && <MyPie />}
    </div>
  );
};

export default MyStat;
