import LastWinningResult from "../components/sub/lastWinningResult";
import CountDown from "../components/common/countdown";
import How from "./howToPlay";
import Login from "../components/common/login";
import ChooseNumbers from "../components/game/chooseNumbers";
import ErrorPage from "./errorPage";
import Lottie from "lottie-react";
import Standard from "../components/game/myNumber";

// img
import StarsMega from "../img/sub/stars-mega.json";
import MegaLogo from "../img/common/USA_Mega_lottery.png";
import PowerLogo from "../img/common/USA_Power_lottery.png";

// tools
import User from "../tools/userInfo";
import axios from "axios";
import amountMaker from "../tools/amountMaker";
import { reset, update } from "../tools/zustandUpdate";
import { useState, useEffect, useMemo } from "react";

import { useLotto } from "../App";
import Favorites from "./DESIGN/NO_USE/favoritesDesign";
import MyNumbers from "../components/game/myNumber";
import Swal from "sweetalert2";
import createAxiosInstance from "../api/axiosConfig";

interface GameProps {
  type: string;
}

interface GameInfo {
  drawnum: number;
  jackpot: number;
  playdate: string;
  orderTime: string;
  drawdate: number;
}

interface LottoProps {
  type: string;
  jackpot?: number;
  exchange?: number;
  auOrderTime?: string;
  isDrawdate?: boolean;
}

const Game = ({ type }: GameProps) => {
  const { api, token, info, setTotalInfo } = User();
  const { lottery } = useLotto();

  const [currentLotto, setCurrentLotto] = useState<LottoProps | undefined>(
    undefined
  );

  const axiosInstance = createAxiosInstance(api, token);

  const isType2 = useMemo<LottoProps | undefined>(() => {
    const lotteryType = type === "mega" ? "MM" : "PB";
    const foundLotto = lottery.find((lot) => lot.type === lotteryType);

    if (foundLotto) {
      return {
        ...foundLotto,
        exchange: Number(foundLotto.exchange),
        isDrawdate: Boolean(foundLotto.isDrawdate),
      };
    }

    return undefined;
  }, [lottery, type]);

  const reward = useMemo(() => {
    return amountMaker(Number(isType2?.jackpot) * Number(isType2?.exchange));
  }, [isType2]);

  // Tab default : myNum
  const [menu, setMenu] = useState("myNum");

  // 게임정보 초기상태 정의
  const [gameInfo, setGameInfo] = useState<GameInfo>({
    drawnum: 0,
    jackpot: 0,
    playdate: "",
    orderTime: "",
    drawdate: 0,
  });

  const [open, setOpen] = useState(false);

  /** 게임 정보 호출 (날짜, 주문시간, 추첨날짜, 추첨번호, 당첨금) */
  const purchaseInfoAxios = (type: string) => {
    const game = type === "mega" ? "mm" : "pb";

    axiosInstance.get(`/purchase/${game}`).then(({ data }) => {
      const Data = data.data[0];

      if (data.message === "success") {
        setGameInfo({
          drawnum: Data.drawnum,
          jackpot: Number(Data.exchange) * Data.jackpot,
          playdate: Data.playdate,
          orderTime: Data.auOrderTime,
          drawdate: Data.isDrawdate,
        });
      }
    });
  };

  // component mount -> run
  useEffect(() => {
    const empty = {
      address: "",
      birth: "",
      cash: 0,
      city: "",
      country: "",
      createdAt: "",
      email: "",
      firstName: "",
      gender: "",
      lastName: "",
      level: 0,
      marketing: "",
      notify: "",
      number: "",
      phone: "",
      unit: "",
      provider: "",
      prize: 0,
      totalPrize: 0,
    };

    // has token update user info
    if (token) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      const origin = payload.iss;

      update(api, token, setTotalInfo);

      if (!origin.includes("/login") && !origin.includes("/refresh")) {
        reset(setTotalInfo);
      }
    } else {
      if (JSON.stringify(empty) !== JSON.stringify(info)) {
        reset(setTotalInfo);
      }
    }
  }, []);

  useEffect(() => {
    // URL 추출 및 초기화
    const search = location.search;
    const split = search.replace("?", "").split("&");
    const tab = split[0].split("=")[1];

    purchaseInfoAxios(location.pathname.replace("/", ""));

    // URL tab setting
    if (tab) {
      setMenu(tab);
    }

    // is Pending -> reload
    if (isType2) {
      if (isType2.isDrawdate) {
        window.location.reload();
      }
    }
  }, [location.search]);

  return (
    <div className={`sub game ${type}`}>
      {/* Game Top Banner */}
      <div className={`game-top-area ${type === "mega" ? "mega" : "power"}`}>
        <img
          src={`${type === "mega" ? MegaLogo : PowerLogo}`}
          alt={`${type === "mega" ? "USA Mega Lottery" : "USA Power Lottery"}`}
          className="logo"
        />

        <div className="game-info-area">
          <p className={`name ${type === "mega" ? "mega" : "power"}`}>
            {type === "mega" ? "USA Mega Lottery" : "USA Power Lottery"}
          </p>
          <p className="prize">
            {isType2?.isDrawdate ? (
              ""
            ) : (
              <span className="unit">{reward?.mark || ""} </span>
            )}
            <span className="num">
              {isType2?.isDrawdate ? "Pending" : `${reward?.amount || ""}`}
            </span>
            <span className="unit">
              {isType2?.isDrawdate ? "" : ` ${reward?.unit || ""}`}
            </span>
          </p>
          <p className="time">
            <CountDown
              date={String(isType2?.auOrderTime || "")}
              pending={Number(isType2?.isDrawdate || 0)}
            />
          </p>
        </div>
      </div>

      {menu === "myNum" ? (
        <MyNumbers type={type} setLogin={setOpen} gameInfo={gameInfo} />
      ) : menu === "fav" ? (
        <Favorites type={type} setLogin={setOpen} gameInfo={gameInfo} />
      ) : menu === "how" ? (
        <How type={type} />
      ) : (
        <ErrorPage />
      )}

      <Login open={open} setOpen={setOpen} />
    </div>
  );
};

export default Game;
