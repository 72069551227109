import { useEffect, useState } from "react";

// ui
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import createAxiosInstance from "../api/axiosConfig";
import User from "../tools/userInfo";

// tpye
interface SynGame {
  type: string;
  system: string;
  qty: number;
  date: string;
  multi: false;
  share: number;
  price: number;
  title: string;
  explain: string;
}

interface SystemMenu {
  idx: number;
  name: string;
  code: string;
  description: string;
  regular: number;
  special: number;
  games: number;
}

const SyndicateCreate = () => {
  const { api, token } = User();

  // GameType 1 PB 2 MM
  const [type, setType] = useState<number>(1);
  const [step, setStep] = useState(0);
  const [selectSystem, setSelectSystem] = useState<string>("STD");
  const [system, setSystem] = useState<SystemMenu[]>([]);
  const [qty, setQty] = useState<number>(1);
  const [cost, setCost] = useState(0);

  // init
  const [game, setGame] = useState<SynGame>({
    type: "",
    system: "",
    qty: 10,
    date: "",
    multi: false,
    share: 0,
    price: 0,
    title: "",
    explain: "",
  });

  const axiosInstance = createAxiosInstance(api, token);

  /** Select handler */
  const handleChange = (
    e: SelectChangeEvent<number | string>,
    kind: string
  ) => {
    if (kind === "type") {
      setType(Number(e.target.value));
    } else if (kind === "system") {
      setSelectSystem(String(e.target.value));
    }
  };

  useEffect(() => {
    const isType = type === 1 ? "pb" : "mm";

    axiosInstance
      .get(`/product/system/${isType}`)
      .then((res) => setSystem(res.data.data));
  }, [type]);

  return (
    <div className="sub syndicate-create">
      <div className="sub-top-area">
        <h3 className="tit">Syndicates</h3>
        <p className="txt">Play with a group and get more chances to win</p>
      </div>

      <div className="create-wrap">
        <div className="stepper-wrap">
          <div
            className={`stepper ${
              step === 0 ? "active" : step > 0 ? "complete" : ""
            }`}
          >
            <p>
              <span className="num">01</span>
              <span className="material-symbols-rounded">check</span>
            </p>
            <div className="stepper-bar"></div>
          </div>

          <div
            className={`stepper ${
              step === 1 ? "active" : step > 1 ? "complete" : ""
            }`}
          >
            <p>
              <span className="num">02</span>
              <span className="material-symbols-rounded">check</span>
            </p>
            <div className="stepper-bar"></div>
          </div>

          <div
            className={`stepper ${
              step === 2 ? "active" : step > 2 ? "complete" : ""
            }`}
          >
            <p>
              <span className="num">03</span>
              <span className="material-symbols-rounded">check</span>
            </p>
            <div className="stepper-bar"></div>
          </div>
        </div>

        {step === 0 && (
          <>
            <div className="step step01">
              <div className="item-wrap">
                <div className="item">
                  <h4 className="tit">Select Game</h4>
                  <FormControl>
                    <Select
                      value={type}
                      onChange={(e) => handleChange(e, "type")}
                    >
                      <MenuItem value="1">USA Power Lottery</MenuItem>
                      <MenuItem value="2">USA Mega Lottery</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className="item">
                  <h4 className="tit">Select Lottery Systems</h4>
                  <FormControl fullWidth>
                    <Select
                      defaultValue={selectSystem}
                      onChange={(e) => handleChange(e, "system")}
                    >
                      <MenuItem value="STD">Standard</MenuItem>
                      {system.map((it, idx) => (
                        <MenuItem key={idx} value={it.code}>
                          {it.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="item">
                  <h4 className="tit">Select Game Numbers</h4>
                  <div className="quantity-control">
                    <div className="btn minus">-</div>
                    <TextField
                      id=""
                      defaultValue={qty}
                      sx={{ width: "60px" }}
                    />
                    <div className="btn plus">+</div>
                  </div>
                </div>
              </div>

              <div className="item">
                <h4 className="tit">Select Draw Date</h4>
                <FormControl sx={{ width: "50%" }}>
                  <Select defaultValue={1}>
                    <MenuItem value="1">Sat Nov 09 2024</MenuItem>
                    <MenuItem value="">Tue Nov 12 2024</MenuItem>
                    <MenuItem value="">Fri Nov 18 2024</MenuItem>
                    <MenuItem value="">Sat Nov 09 2024</MenuItem>
                    <MenuItem value="">Sat Nov 09 2024</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="item">
                <h4 className="tit">Multiplier</h4>
                <div className="layout">
                  <FormControlLabel
                    control={<Switch />}
                    label="Multiplier added"
                  />
                  <div className="txt-wrap">
                    <p className="txt">$ 3.45 per combination</p>
                    <p className="exp">
                      Boost the potential winnings up to 5 times on all
                      non-jackpot prizes!
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="step step02 shortfall">
              <h4 className="tit">Total</h4>
              <p>
                <span className="num">$ 200.00</span>
                <span className="notice">$ 180.00 부족합니다.</span>
              </p>
            </div>
          </>
        )}

        {step === 1 && (
          <div className="step step03">
            <div className="item">
              <h4 className="tit">The Number of Share</h4>
              <div className="layout">
                <TextField
                  id=""
                  defaultValue={cost}
                  sx={{ width: "120px" }}
                  onChange={(event) => {
                    setCost(Number(event.target.value) || 0);
                  }}
                />
                <p className="cost">
                  Cost per share : <span>$ {cost / 10}</span>
                </p>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="$ 1"
                />
                <div className="btn style01 sm">$ 1</div>
              </div>
            </div>

            <div className="item">
              <h4 className="tit">Syndicate Title</h4>
              <TextField
                id=""
                defaultValue="USA Power Lottery Standard"
                sx={{ width: "50%" }}
              />

              <h4 className="tit" style={{ marginTop: "24px" }}>
                Syndicate Explain
              </h4>
              <TextField
                id=""
                defaultValue="USA Power Lottery Standard"
                fullWidth
                multiline
                rows={4}
              />
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="step step04">
            <div className="ticket-wrap">
              <div className="ticket">
                <div className="ticket-tit-wrap">
                  <p>Game #1</p>
                  <div className="btn-wrap">
                    <span className="btn material-symbols-rounded">bolt</span>
                    <span className="btn material-symbols-rounded">mop</span>
                    <span className="btn material-symbols-rounded">delete</span>
                  </div>
                </div>

                <div className="picked-ball-wrap">
                  <div className="ball-wrap">
                    <div className="ball">2</div>
                    <div className="ball">3</div>
                    <div className="ball">4</div>
                    <div className="ball">1</div>
                    <div className="ball">10</div>
                    <div className="ball power">15</div>
                    <span className="material-symbols-rounded">
                      keyboard_arrow_down
                    </span>
                  </div>

                  <div>
                    <div className="edit-ball-wrap">
                      <div className="ball-count">
                        <div className="tit-count-wrap">
                          <p className="tit">Ball</p>
                          <p className="count">
                            <span>1</span>
                            of 5
                          </p>
                        </div>
                      </div>

                      <div className="ball-wrap">
                        {Array(69)
                          .fill(0)
                          .map((_, i) => (
                            <div key={i} className="ball">
                              {i + 1}
                            </div>
                          ))}
                      </div>

                      <div
                        className="tit-count-wrap"
                        style={{ marginTop: "12px" }}
                      >
                        <p className="power">Power ball</p>
                        <p className="count">
                          <span>0</span> of 1
                        </p>
                      </div>

                      <div className="ball-wrap power">
                        {Array(26)
                          .fill(0)
                          .map((_, i) => (
                            <div key={i} className="ball">
                              {i + 1}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="ticket">
                <div className="ticket-tit-wrap">
                  <p>Game #1</p>
                  <div className="btn-wrap">
                    <span className="btn material-symbols-rounded">bolt</span>
                    <span className="btn material-symbols-rounded">mop</span>
                    <span className="btn material-symbols-rounded">delete</span>
                  </div>
                </div>

                <div className="picked-ball-wrap">
                  <div className="ball-wrap">
                    <div className="ball">2</div>
                    <div className="ball">3</div>
                    <div className="ball">4</div>
                    <div className="ball">1</div>
                    <div className="ball">10</div>
                    <div className="ball power">15</div>
                    <span className="material-symbols-rounded">
                      keyboard_arrow_down
                    </span>
                  </div>

                  <div>
                    <div className="edit-ball-wrap">
                      <div className="ball-count">
                        <div className="tit-count-wrap">
                          <p className="tit">Ball</p>
                          <p className="count">
                            <span>1</span>
                            of 5
                          </p>
                        </div>
                      </div>

                      <div className="ball-wrap">
                        {Array(69)
                          .fill(0)
                          .map((_, i) => (
                            <div key={i} className="ball">
                              {i + 1}
                            </div>
                          ))}
                      </div>

                      <div
                        className="tit-count-wrap"
                        style={{ marginTop: "12px" }}
                      >
                        <p className="power">Power ball</p>
                        <p className="count">
                          <span>0</span> of 1
                        </p>
                      </div>

                      <div className="ball-wrap power">
                        {Array(26)
                          .fill(0)
                          .map((_, i) => (
                            <div key={i} className="ball">
                              {i + 1}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="step step05">
            <div className="info-wrap">
              <h3>Syndicate Summary</h3>
              <div className="item-wrap">
                <div className="item">
                  <h4 className="tit">Game</h4>
                  <p className="txt">
                    <TextField
                      id=""
                      disabled
                      defaultValue="USA Power Lottery"
                    />
                  </p>
                </div>
                <div className="item">
                  <h4 className="tit">Systems</h4>
                  <p className="txt">
                    <TextField id="" disabled defaultValue="Standard" />
                  </p>
                </div>
                <div className="item">
                  <h4 className="tit">Total Count</h4>
                  <p className="txt">
                    <TextField
                      id=""
                      disabled
                      defaultValue="10"
                      sx={{ width: "60px" }}
                    />
                  </p>
                </div>
              </div>
              <div className="item-wrap">
                <div className="item">
                  <h4 className="tit">Draw Date</h4>
                  <p className="txt">
                    <TextField id="" disabled defaultValue="Sat Nov 09 2024" />
                  </p>
                </div>
                <div className="item">
                  <h4 className="tit">Multiplier</h4>
                  <p className="txt">
                    <TextField
                      id=""
                      disabled
                      defaultValue="O"
                      sx={{ width: "60px" }}
                    />
                  </p>
                </div>
              </div>
              <div className="item">
                <h4 className="tit">Title</h4>
                <p className="txt">
                  <TextField
                    id=""
                    disabled
                    defaultValue="USA Power Lottery"
                    sx={{ width: "50%" }}
                  />
                </p>

                <h4 className="tit" style={{ marginTop: "24px" }}>
                  Explain
                </h4>
                <p className="txt">
                  <TextField
                    id=""
                    fullWidth
                    multiline
                    rows={4}
                    disabled
                    defaultValue="단돈 1달러에 살 수 있는 로터리"
                  />
                </p>
              </div>
              <div className="item">
                <h4 className="tit">Selected Ball Numbers</h4>
                <div className="show-number">
                  <div className="number-wrap">
                    <div className="number">
                      <p className="">#1</p>
                      <div className="ball-wrap">
                        <div className="ball">1</div>
                        <div className="ball">1</div>
                        <div className="ball">1</div>
                        <div className="ball">1</div>
                        <div className="ball">1</div>
                        <div className="ball power">25</div>
                      </div>
                    </div>
                    <div className="number">
                      <p className="">#1</p>
                      <div className="ball-wrap">
                        <div className="ball">1</div>
                        <div className="ball">1</div>
                        <div className="ball">1</div>
                        <div className="ball">1</div>
                        <div className="ball">1</div>
                        <div className="ball power">25</div>
                      </div>
                    </div>
                    <div className="number">
                      <p className="">#1</p>
                      <div className="ball-wrap">
                        <div className="ball">1</div>
                        <div className="ball">1</div>
                        <div className="ball">1</div>
                        <div className="ball">1</div>
                        <div className="ball">1</div>
                        <div className="ball power">25</div>
                      </div>
                    </div>
                    <div className="number">
                      <p className="">#1</p>
                      <div className="ball-wrap">
                        <div className="ball">1</div>
                        <div className="ball">1</div>
                        <div className="ball">1</div>
                        <div className="ball">1</div>
                        <div className="ball">1</div>
                        <div className="ball power">25</div>
                      </div>
                    </div>
                    <div className="number">
                      <p className="">#1</p>
                      <div className="ball-wrap">
                        <div className="ball">1</div>
                        <div className="ball">1</div>
                        <div className="ball">1</div>
                        <div className="ball">1</div>
                        <div className="ball">1</div>
                        <div className="ball power">25</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="agree-wrap">
              <FormControlLabel
                control={<Checkbox />}
                label="신디케이트 생성 후에는 삭제, 변경이 어렵습니다."
              />
            </div>
          </div>
        )}

        <div className="btn-wrap prev-next">
          {step !== 0 && (
            <div
              className="btn style01 md prev"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Prev
            </div>
          )}
          {step !== 3 ? (
            <div
              className="btn style01 md spc next disabled"
              onClick={() => {
                setStep(step + 1);
              }}
            >
              Next Step
            </div>
          ) : (
            <div className="btn style01 md spc">Create Syndicate</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SyndicateCreate;
