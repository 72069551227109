const AboutUs = () => {
  return (
    <div className="sub aboutus">
      <div className="top-info-area inner">
        <div className="txt-area">
          <p className="f40">About Us</p>
          <p className="f18 txt">Real tickets, no bets.</p>
        </div>
      </div>
      <div className="inner cont">
        <p className="f16">
          With 10 years of experience in the global lottery industry, we are the
          smarter choice. Real tickets, no bets.
          <br />
          <br /> We hold both a Mail Order Lotteries Licence and an Internet
          Gaming Licence issued and actively regulated by the Northern Territory
          Government of Australia.
          <br />
          <br />
          To ensure compliance with those regulatory requirements of our
          licenses we undertake an annual financial audit and an annual audit of
          our operational procedures, with both reports independently submitted
          to the Northern Territory Government. This is in addition to an
          auditing of customer orders against tickets purchased and the
          resulting draw outcomes. The entire audit process enables players,
          agents and suppliers to have complete confidence that business is
          conducted in a professional manner.
        </p>

        <div className="box style01 flex">
          <span className="material-symbols-outlined f60">book_online</span>
          <p className="f18">
            When you purchase a ticket in a lottery conducted by The Lottery
            Cluster, we will purchase a matching ticket in the relevant overseas
            lottery. This ensures that we can offer the biggest jackpots in the
            world and are guaranteed to be able to pay out any prize.
          </p>
        </div>

        <p className="f16">
          Our Executive team including our Executive Chairman, Chief Executive
          Officer and Chief Operating Officer have over 50 years of combined
          experience in the global lottery industry and they lead an amazing
          team of experienced, passionate and hard-working individuals based
          here in Australia.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
