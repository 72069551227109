// tools
import { useState } from "react";

// comp
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";

// constants
const steps = ["Choose Numbers", "Checkout", "Review your purchase"];

// img
import MegaChoose01 from "../../img/sub/mega-choose-img01.png";
import MegaChoose02 from "../../img/sub/mega-choose-img02.png";
import MegaChoose03 from "../../img/sub/mega-quick-img03.png";
import PowerChoose01 from "../../img/sub/power-choose-img01.png";
import PowerChoose02 from "../../img/sub/power-choose-img02.png";
import PowerChoose03 from "../../img/sub/power-quick-img03.png";

interface HowProps {
  type: string;
}

const Choose = ({ type }: HowProps) => {
  const [activeStep, setActiveStep] = useState(0);

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep = isLastStep()
      ? steps.findIndex((_, i) => !i)
      : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  return (
    <>
      <div className="stepper-area">
        <Stepper
          nonLinear
          activeStep={activeStep}
          className="stepper"
          sx={{ width: "50%" }}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div className="btn-wrap">
          <div
            className={`btn style01 sm ${activeStep === 0 ? "disabled" : ""}`}
            color="inherit"
            onClick={() => {
              activeStep !== 0 ? handleBack() : null;
            }}
          >
            Back
          </div>
          <div className="btn style01 sm" onClick={handleNext}>
            Next
          </div>
        </div>
      </div>
      {activeStep === 0 && (
        <div className="step01 flex">
          <div className="txt-wrap">
            <h4 className="f24">
              <span>{activeStep + 1}</span>
              {steps[activeStep]}
            </h4>
            <p className="f16">
              Each game played costs $2.00.
              <br />
              Pick 5 numbers between 1 - {type === "power" ? 69 : 70} or select
              Quick Pick.
            </p>
          </div>
          <img
            src={type === "power" ? PowerChoose01 : MegaChoose01}
            alt="Choose Number Play Step 01"
          />
        </div>
      )}

      {activeStep === 1 && (
        <div className="step02">
          <div className="txt-wrap">
            <h4 className="f24">
              <span>{activeStep + 1}</span>
              {steps[activeStep]}
            </h4>
            <p className="f16">
              Pick 1 {type === "power" ? "Power ball" : "Mega ball"} between 1 -
              {type === "power" ? 26 : 25} or select Quick Pick.
              <br />
              To Edit a line, select the line, click a previously picked number
              and select a new number.
              <br />
              To Delete a line click on the trash icon next to the line.
            </p>
          </div>
          <img
            src={type === "power" ? PowerChoose02 : MegaChoose02}
            alt="Choose Number Play Step 01"
          />
        </div>
      )}

      {activeStep === 2 && (
        <div className="step03">
          <div className="txt-wrap">
            <h4 className="f24">
              <span>{activeStep + 1}</span>
              {steps[activeStep]}
            </h4>
            <p className="f16">
              Review your ticket summary, draw dates, total amount.
            </p>
          </div>
          <img
            src={type === "power" ? PowerChoose03 : MegaChoose03}
            alt="Choose Number Play Step 01"
          />
        </div>
      )}
    </>
  );
};

export default Choose;
