// comp
import Article from "./article";

// img
const images = [
  "https://cdn.pixabay.com/photo/2021/09/19/21/27/speedboat-6639122_1280.jpg",
  "https://cdn.pixabay.com/photo/2019/07/21/19/53/skyline-4353504_1280.jpg",
  "https://cdn.pixabay.com/photo/2018/07/31/22/22/vancouver-3576062_1280.jpg",
  "https://cdn.pixabay.com/photo/2018/07/09/18/03/cruise-ship-3526709_1280.jpg",
];

const Yacht = () => {
  return (
    <div className="maga-yacht">
      <section className="maga-section">
        <h3 className="tit">Latest Articles</h3>
        <div className="article">
          {images.map((image, index) => (
            <Article image={image} index={index} key={index} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default Yacht;
