import { useEffect, useState } from "react";
import User from "../tools/userInfo";
import CartItem from "../components/cart/cartItem";
import Swal from "sweetalert2";
import { update } from "../tools/zustandUpdate";
import { useLotto } from "../App";
import createAxiosInstance from "../api/axiosConfig";

// type
export interface Ball {
  white: number;
  special: number;
}

export interface BallSet {
  [key: string]: Ball;
  mega: Ball;
  power: Ball;
}

export interface Nums {
  idx: number;
  num: string;
}

export interface CartItems {
  draws: number; // 회차 갯수
  idx: number; // 게임키
  jackpot: number; // 당첨금
  methodCode: string; // 게임 종류
  multiplier: string; // 멀티 유무
  nums: Nums[]; // 게임 모음
  playdate: string[]; // 구매할 회차 날짜
  productCode: string; // 게임 종목
  productName: string; // 게임 이름
  subscription: string; // 서브스크랩 유무
  systemCode: string; // 게임 상세 종류
  ticketQty: number; // 게임 갯수
  totalAmount: number; // 게임 가격
  auOrderTime?: string; // 신디케이트 마감시간
  ticketIssued: number; // 신디케이트 발행표
  ticketSold: number; // 신디케이트 판매표
  unitPrice: string; // 신디케이트 표가격
}

// constant
const BallSet: BallSet = {
  mega: {
    white: 70,
    special: 25,
  },
  power: {
    white: 69,
    special: 26,
  },
};

const Cart = () => {
  const { api, token, setToken, setTotalInfo, info } = User();
  const { checkNoLoginCart, noLoginCart, limitCart } = useLotto();
  const [items, setItems] = useState<CartItems[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [delTarget, setDelTarget] = useState<number>(0);

  const axiosInstance = createAxiosInstance(api, token);

  // local
  const noLg = JSON.parse(localStorage.getItem("noLgCart") || "[]");
  const localCartCnt = noLg.length;

  const isLimit = info.cart + localCartCnt;

  // func
  const totalPrice = (items: CartItems[]) => {
    let total = 0;
    for (let i = 0; i < items.length; i++) {
      total += Number(items[i].totalAmount);
    }
    return total;
  };

  // update cartCnt
  const updateCart = () => {
    update(api, token, setTotalInfo);
  };

  // effect
  useEffect(() => {
    if (items.length === 0) {
      setLoading(true);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [items]);

  useEffect(() => {
    if (token.length < 1) {
      setItems(noLg);
      checkNoLoginCart();
    } else {
      updateCart();
      loginCart();
      setTimeout(() => {
        getCart();
      }, 100);
    }
  }, []);

  // API

  /** 로그인시 비로그인 카트 서버로 전송 */
  const loginCart = () => {
    if (token.length > 1 && Array.isArray(noLg) && noLg.length > 0) {
      Swal.fire({
        icon: "question",
        title: "Items were added to your cart before logging in.",
        text: "Add them to your logged-in account's cart?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonColor: "#af7af1",
        cancelButtonColor: "#876aab",
      }).then((result) => {
        if (result.isConfirmed) {
          if (isLimit > limitCart) {
            Swal.fire({
              icon: "warning",
              title: `The cart can hold a maximum of ${limitCart} items.`,
              html: `${info.cart} items in your account cart,<br>
              ${localCartCnt} items in the guest cart, <br>
              The total exceeds ${isLimit} items.
              `,
              showCancelButton: true,
              cancelButtonText: "cancel",
              confirmButtonText: "delete guest cart",
              confirmButtonColor: "#af7af1",
              cancelButtonColor: "#876aab",
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage.removeItem("noLgCart");
              }
            });
            return;
          } else {
            const normalGame = noLg.filter((it) => it.methodCode !== "SYN");
            const syndiGame = noLg.filter((it) => it.methodCode === "SYN");

            const normalData = normalGame.map((it) => {
              return {
                productCode: it.productCode,
                methodCode: it.methodCode,
                systemCode: it.systemCode,
                multiplier: it.multiplier,
                draws: it.draws,
                ticketQty: it.ticketQty,
                subscription: it.subscription,
                totalAmount: it.totalAmount,
                nums: it.nums.map((it: any) => {
                  return {
                    ...it,
                    num: it.num.split(",").map((n: string) => {
                      return isNaN(Number(n)) ? n : Number(n); // 숫자가 아니면 그대로 반환
                    }),
                  };
                }),
              };
            });

            const syndiData = syndiGame.map((it) => {
              return {
                idx: it.syndicateIdx,
                ticketQty: it.ticketQty,
                amount: it.totalAmount,
              };
            });

            if (syndiData.length > 0) {
              axiosInstance
                .post(`/cart/syndicate`, syndiData)
                .then((res) => console.log(res))
                .catch((error) => console.error(error));
            }

            if (normalData) {
              axiosInstance
                .post(`/cart`, normalData)
                .then((res) => console.log(res))
                .catch((error) => console.error(error));
            }

            setTimeout(() => {
              localStorage.removeItem("noLgCart");
              updateCart();
              getCart();
            }, 500);
          }
        } else {
          // no
        }
      });
    } else {
      return;
    }
  };

  /** 카트 개별 삭제 */
  const deleteCart = (target: number) => {
    if (token.length === 0) {
      setDelTarget(target);
      const copy = [...items];
      const updateCart = copy.filter((it) => it.idx != target);

      setTimeout(() => {
        localStorage.setItem("noLgCart", JSON.stringify(updateCart));
        setItems(updateCart);
        checkNoLoginCart();
      }, 300);
    } else {
      setDelTarget(target);

      axiosInstance
        .delete(`/cart/${target}`)
        .then((res) => {
          setTimeout(() => {
            setItems((prev) => prev.filter((item) => item.idx !== target));
          }, 300);
          updateCart();
        })
        .catch((error) => console.log(error));
    }
  };

  // delCart typeCheck
  const delCartType = (target: number | string) => {
    if (typeof target === "string") {
      deleteCart(parseInt(target, 10));
    } else {
      deleteCart(target);
    }
  };

  /** 카트 전체 삭제 */
  const clearCart = () => {
    Swal.fire({
      icon: "question",
      title: "Are you sure you want to empty the cart?",
      showCancelButton: true,
      confirmButtonText: "yes",
      cancelButtonText: "no",
      confirmButtonColor: "#af7af1",
      cancelButtonColor: "#876aab",
    }).then((result) => {
      if (result.isConfirmed) {
        if (token.length === 0) {
          localStorage.removeItem("noLgCart");
          setItems([]);
          checkNoLoginCart();
        } else {
          axiosInstance
            .delete(`/cart/clear`)
            .then((res) => {
              Swal.fire({
                title: "Success clear your cart",
                icon: "success",
                allowOutsideClick: false,
                showConfirmButton: false,
                confirmButtonColor: "#af7af1",
                cancelButtonColor: "#876aab",
                timer: 1000,
                didClose: () => {
                  window.location.reload();
                },
              });
              localStorage.removeItem("noLgCart");
            })
            .catch((error) => console.log(error));
        }
      }
    });
  };

  const getCart = () => {
    axiosInstance
      .get(`/cart`)
      .then((res) => setItems(res.data.data || []))
      .catch((error) => console.log(error));
  };

  return (
    <div className="sub cart">
      <div className="sub-top-area">
        <h3 className="tit">Checkout</h3>
        <p className="price">
          Total : <span>$ {totalPrice(items).toFixed(2)}</span>
        </p>
        <div className="btn-wrap">
          <div className="btn style01 sm delete" onClick={() => clearCart()}>
            <span className="material-symbols-rounded">delete</span>
          </div>
          <div className="btn style01 sm spc">Continue</div>
        </div>
      </div>
      {/* item component wrap */}
      <div className="cart-wrap">
        {items.length === 0 ? (
          <div className="empty-cart">
            <h3>Unfortunately, your cart is empty</h3>
            <p>Please add something to your cart</p>
            <div className="btn-wrap">
              <div
                className="btn style01 md mega"
                onClick={() => (window.location.href = "/mega")}
              >
                USA Mega Lottery play
                {/* <span className="material-symbols-rounded">chevron_right</span> */}
              </div>
              <div
                className="btn style01 md power"
                onClick={() => (window.location.href = "/power")}
              >
                USA Power Lottery play
                {/* <span className="material-symbols-rounded">chevron_right</span> */}
              </div>
            </div>
          </div>
        ) : (
          items.map((it) => (
            <CartItem
              key={it.idx}
              item={it}
              delCartType={delCartType}
              setItems={setItems}
              getCart={getCart}
              updateCart={updateCart}
              loading={loading}
              delTarget={delTarget}
            />
          ))
        )}
      </div>

      {/* bottom check */}

      <div className="cart-bottom-area">
        <h3 className="tit">Checkout</h3>
        <p className="price">
          Total : <span>$ {totalPrice(items).toFixed(2)}</span>
        </p>
        <div className="btn-wrap">
          <div className="btn style01 sm delete" onClick={() => clearCart()}>
            <span className="material-symbols-rounded">delete</span>
          </div>
          <div className="btn style01 sm spc">Continue</div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
