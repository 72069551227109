import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const MyPie: React.FC = () => {
  const data = {
    labels: [
      "division 6",
      "division 5",
      "division 4",
      "division 3",
      "division 2",
      "division 1",
    ],

    datasets: [
      {
        label: "My get divisions",
        data: [1, 2, 1, 3, 5, 10],
        backgroundColor: [
          "#fe7554",
          "#4353ff",
          "#efab3b",
          "#76c991",
          "#6945bc",
          "#33d6ed",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
  };

  return (
    <div className="pie-chart">
      <div className="pie-wrap">
        <Pie data={data} options={options} />
      </div>

      <div className="label-wrap">
        {data.labels.map((label, index) => (
          <div className="label" key={index}>
            <span
              style={{
                backgroundColor: data.datasets[0].backgroundColor[index],
              }}
            ></span>
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyPie;
