// tools
import React, { useEffect } from "react";
import { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { CartItems, Nums } from "../../pages/cart";
import User from "../../tools/userInfo";
import amountMaker from "../../tools/amountMaker";

// img
import MegaBallLogo from "../../img/common/USA_Mega_lottery.png";
import PowerBallLogo from "../../img/common/USA_Power_lottery.png";
// import MegaBallLogo from "../../img/common/USA_Mega_lottery_sm.png";
// import PowerBallLogo from "../../img/common/USA_Power_lottery_sm.png";

// ui
import { Skeleton } from "@mui/material";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

// comp
import ShowNums from "./showNums";
import { useLotto } from "../../App";
import createAxiosInstance from "../../api/axiosConfig";
import { error } from "console";

// type
interface CartItemProps {
  item: CartItems;
  setItems: React.Dispatch<React.SetStateAction<CartItems[]>>;
  delCartType: (target: number | string) => void;
  getCart: () => void;
  updateCart: () => void;
  loading: boolean;
  delTarget: number;
}

const CartItem = ({
  item,
  delCartType,
  setItems,
  getCart,
  updateCart,
  loading,
  delTarget,
}: CartItemProps) => {
  // tools
  const { api, token } = User();
  const { lottery } = useLotto();

  // stat
  const [ballEditOpen, setBallEditOpen] = useState<boolean>(false);
  const [isPicked, setIsPicked] = useState<boolean>(false);

  const [cartItemOpen, setCartItemOpen] = useState<boolean>(false);
  const [stopMulti, setStopMulti] = useState<boolean>(false);

  const axiosInstance = createAxiosInstance(api, token);

  // local
  const storedData = JSON.parse(localStorage.getItem("noLgCart") || "[]");

  const findCardType = (code: string) => {
    const isPending = lottery.filter((it) => it.type === code);
    return isPending[0].isDrawdate;
  };

  const amountCur = (option: string, count: number) => {
    if (option === "Y") {
      return (count * 10.45).toFixed(2);
    } else {
      return (count * 7).toFixed(2);
    }
  };

  const isMode = (code: string) => {
    if (code === "MM") {
      return MegaBallLogo;
    } else {
      return PowerBallLogo;
    }
  };

  const isBall = (type: string) => {
    if (type === "MM") {
      return "mega";
    } else {
      return "power";
    }
  };

  const isType2 = (type: string) => {
    const lotteryType = type === "mega" ? "MM" : "PB";
    return lottery.find((lot) => lot.type === lotteryType);
  };

  const exchange = isType2(item.productCode)?.exchange;

  //** is closed syndicate check */
  const hasTimePassed = (auOrderTime: string) => {
    const orderTime = new Date(auOrderTime);
    const currentTime = new Date();
    return currentTime > orderTime;
  };

  /** change Multiplier */
  const editCartType = (target: number | string, multi: string) => {
    if (typeof target === "string") {
      handleMulti(parseInt(target, 10), multi);
    } else {
      handleMulti(target, multi);
    }
  };

  // api
  const delTicket = (target: number, itemKey: number) => {
    if (token.length === 0) {
      const findData = storedData.find((it: any) => it.idx === target);

      if (findData) {
        const updatedNums = findData.nums.filter(
          (it: any) => it.idx !== itemKey
        );

        const checkQ = findData.ticketQty - 1;

        const updatedData = storedData.map((storedItem: any) => {
          if (storedItem.idx === target) {
            return {
              ...storedItem,
              nums: updatedNums,
              ticketQty: storedItem.ticketQty - 1,
              totalAmount: amountCur(storedItem.multiplier, checkQ),
            };
          }
          return storedItem;
        });

        localStorage.setItem("noLgCart", JSON.stringify(updatedData));
        setItems(updatedData);
      }
    } else {
      axiosInstance
        .delete(`/cart/${target}/nums/${itemKey}`)
        .then((res) => {
          getCart();
          updateCart();
        })
        .catch((error) => console.error(error));
    }
  };

  /** SYN QTY change */
  const chagneSynQty = (target: number, qty: number) => {
    if (token.length === 0) {
      const findData = storedData.find((it: any) => it.idx === target);

      const updateData = {
        ...findData,
        ticketQty: qty,
        totalAmount: qty * Number(findData.unitPrice),
      };
      const updatedStoredData = storedData.map((item: any) =>
        item.idx === target ? updateData : item
      );
      setItems(updatedStoredData);
      localStorage.setItem("noLgCart", JSON.stringify(updatedStoredData));
    } else {
      const data = { ticketQty: qty };
      axiosInstance
        .put(`cart/syndicate/${target}`, data)
        .then((res) => getCart())
        .catch((error) => console.error(error));
    }
  };

  const handleMulti = (
    target: number | undefined,
    multi: string | undefined
  ) => {
    setStopMulti(true);
    const changeMulti = multi === "N" ? "Y" : "N";

    setTimeout(() => {
      setStopMulti(false);
    }, 2000);

    if (token.length === 0) {
      const findData = storedData.find((it: any) => it.idx === target);

      const changeAmount = amountCur(changeMulti, findData.ticketQty);

      const updatedData = storedData.map((storedItem: CartItems) => {
        if (storedItem.idx === target) {
          return {
            ...storedItem,
            multiplier: changeMulti,
            totalAmount: changeAmount,
          };
        }
        return storedItem; // 나머지 유지
      });

      localStorage.setItem("noLgCart", JSON.stringify(updatedData));

      setItems(updatedData);
    } else {
      setItems((prev) => {
        if (!Array.isArray(prev)) return prev;
        return prev.map((item) =>
          item.idx === target ? { ...item, multiplier: changeMulti } : item
        );
      });

      const data = {
        multiplier: changeMulti,
      };

      axiosInstance
        .put(`/cart/${target}`, data)
        .then((res) => getCart())
        .catch((error) => console.error(error));
    }
  };

  useEffect(() => {
    if (item.ticketQty === 0) {
      delCartType(item.idx);
    }
  }, [item]);

  // card type func
  const isCardType = (card: CartItems) => {
    const reward = amountMaker(Number(card.jackpot) * Number(exchange));

    const ticketsAvailable =
      token.length !== 0
        ? card.ticketIssued - card.ticketSold
        : card.ticketIssued;
    if (
      card.methodCode === "SYN" &&
      card.auOrderTime &&
      hasTimePassed(card.auOrderTime)
    ) {
      return (
        <div className={`item ${hasTimePassed(card.auOrderTime) && "closed"}`}>
          <div
            className={`top-area ${cartItemOpen && "open"}`}
            onClick={() => {
              setCartItemOpen((prev) => !prev);
            }}
          >
            <div className="game">
              <img src={isMode(card.productCode)} />
              <div className="name-tag-wrap">
                <p className="name">{card.productName}</p>
                {card.methodCode === "SYN" && (
                  <p className="tag syndicate">Syndicate</p>
                )}
              </div>
            </div>
            <div className="num-price-wrap">
              <p className="num">{card.ticketQty} Games</p>
              <p className="price">$ {card.totalAmount}</p>
              <p className="txt">The game has ended</p>
            </div>
            <div className="btn-wrap">
              <div
                className="btn style01 sm delete"
                onClick={(e) => {
                  e.stopPropagation();
                  delCartType(item.idx);
                }}
              >
                <span className="material-symbols-rounded">delete</span>
              </div>
              <span className="material-symbols-rounded arr">
                keyboard_arrow_down
              </span>
            </div>
          </div>
        </div>
      );
    } else if (card.methodCode === "SYN") {
      const spBall = card.productCode;
      return (
        <div className={`item ${delTarget === item.idx ? "delete" : ""}`}>
          <div
            className={`top-area ${cartItemOpen && "open"}`}
            onClick={() => {
              setCartItemOpen((prev) => !prev);
            }}
          >
            <div className="game">
              <img src={isMode(card.productCode)} />
              <div className="name-tag-wrap">
                <p className="name">{card.productName}</p>
                {card.methodCode === "SYN" && (
                  <p className="tag syndicate">Syndicate</p>
                )}
                {card.multiplier === "Y" && (
                  <p className="tag multiplier">Multiplier</p>
                )}
              </div>
            </div>
            {/* <p className="num">{card.ticketQty} Games</p> */}
            <div className="num-price-wrap">
              <FormControl fullWidth>
                <InputLabel id="Syndigate">Games</InputLabel>
                <Select
                  labelId="Syndigate"
                  id="Syndigate"
                  label="Syndigates"
                  onClick={(e) => e.stopPropagation()}
                  defaultValue={card.ticketQty}
                >
                  {Array.from({ length: ticketsAvailable }, (_, index) => (
                    <MenuItem
                      key={index}
                      value={index + 1}
                      onClick={() => chagneSynQty(card.idx, index + 1)}
                    >
                      {index + 1} {index === 0 ? "Game" : "Games"} - $
                      {Number(card.unitPrice) * (index + 1)}
                      .00
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <p className="price">
                ${" "}
                {token.length !== 0
                  ? card.totalAmount
                  : card.ticketQty * Number(card.unitPrice)}
              </p>
            </div>
            <div className="btn-wrap">
              <div
                className="btn style01 sm delete"
                onClick={(e) => {
                  e.stopPropagation();
                  delCartType(item.idx);
                }}
              >
                <span className="material-symbols-rounded">delete</span>
              </div>
              <span className="material-symbols-rounded arr">
                keyboard_arrow_down
              </span>
            </div>
          </div>

          <div className={`vertical-trans ${cartItemOpen ? "open" : "close"}`}>
            <div className="bottom-area">
              <div className="game">
                {findCardType(item.productCode) === 1 ? (
                  <p className="prize">Pending</p>
                ) : (
                  <p className="prize">
                    <span className="unit">{reward.mark} </span>
                    <span className="unit">{reward.amount} </span>
                    <span className="unit">{reward.unit}</span>
                  </p>
                )}
                {Array.isArray(card.playdate) ? (
                  card.playdate.map((it, idx) => (
                    <p key={idx} className="date">
                      {it}
                    </p>
                  ))
                ) : (
                  <p className="date">{card.playdate}</p>
                )}
              </div>
              {/* Syndicate STD */}
              {card.systemCode === null && (
                <div className="show-number">
                  <div className="number-wrap">
                    {card.nums.map((it, idx) => (
                      <div key={idx} className="item">
                        <p>#{idx + 1}</p>
                        <div className="ball-wrap">
                          {it.num.split(",").map((num, idx) => (
                            <div
                              key={idx}
                              className={`ball ${
                                idx === it.num.split(",").length - 1
                                  ? isBall(card.productCode)
                                  : ""
                              }`}
                            >
                              {num}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* Syndicate GUA */}
              {cartItemOpen && card.systemCode === "GUA" && (
                <div className="ticket-wrap">
                  {card.nums.map((it: Nums, idx: number) => (
                    <div className="item" key={idx}>
                      <div className="ticket-tit-wrap">
                        <p>Game #{idx + 1}</p>
                        <div className="btn-wrap"></div>
                      </div>
                      <div className="picked-ball-wrap">
                        <div className="ball-wrap">
                          {it.num.split(",").map((it, idx, arr: string[]) => (
                            <div
                              key={idx}
                              className={`ball ${
                                idx === arr.length - 1 ? "guaranteed" : ""
                              }`}
                            >
                              {it}
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* ---- show nums ---- */}
                      <ShowNums
                        nums={it.num}
                        code={card.productCode}
                        sysCode={card.systemCode}
                      />
                      {/* ---- show nums ---- */}
                    </div>
                  ))}
                </div>
              )}

              {/* Syndicate System  */}
              {cartItemOpen &&
                card.systemCode !== null &&
                card.systemCode.includes("ST") && (
                  <div className="ticket-wrap">
                    {card.nums.map((it: Nums, idx: number) => (
                      <div className="item" key={idx}>
                        <div className="ticket-tit-wrap">
                          <p>Game #{idx + 1}</p>
                          <div className="btn-wrap"></div>
                        </div>
                        <div className="picked-ball-wrap">
                          <div className="ball-wrap">
                            {it.num.split(",").map((it, idx, arr: string[]) => (
                              <div
                                key={idx}
                                className={`ball ${
                                  idx === arr.length - 1 &&
                                  isBall(card.productCode)
                                }`}
                              >
                                {it}
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* ---- show nums ---- */}
                        <ShowNums
                          nums={it.num}
                          code={card.productCode}
                          sysCode={card.systemCode}
                        />
                        {/* ---- show nums ---- */}
                      </div>
                    ))}
                  </div>
                )}
            </div>
          </div>
        </div>
      );
    } else if (card?.methodCode === "STD") {
      const spBall = card.productCode;
      return (
        <div className={`item ${delTarget === item.idx ? "delete" : ""}`}>
          <div
            className={`top-area ${cartItemOpen && "open"}`}
            onClick={() => {
              setCartItemOpen((prev) => !prev);
            }}
          >
            <div className="game">
              <img src={isMode(card.productCode)} />
              <div className="name-tag-wrap">
                <p className="name">{card.productName}</p>
                {card.multiplier === "Y" && (
                  <p className="tag multiplier">Multiplier</p>
                )}
                {card.subscription === "Y" && (
                  <p className="tag subscription">Subscription</p>
                )}
              </div>
            </div>
            <div className="num-price-wrap">
              <p className="num">{card.ticketQty} Games</p>
              <p className="price">$ {card.totalAmount}</p>
            </div>
            <div className="btn-wrap">
              <div
                className="btn style01 sm delete"
                onClick={(e) => {
                  e.stopPropagation();
                  delCartType(item.idx);
                }}
              >
                <span className="material-symbols-rounded">delete</span>
              </div>
              <span className="material-symbols-rounded arr">
                keyboard_arrow_down
              </span>
            </div>
          </div>
          <div className={`vertical-trans ${cartItemOpen ? "open" : "close"}`}>
            <div className="bottom-area">
              <div className="game">
                {findCardType(item.productCode) === 1 ? (
                  <p className="prize">Pending</p>
                ) : (
                  <p className="prize">
                    <span className="unit">{reward.mark} </span>
                    <span className="unit">{reward.amount} </span>
                    <span className="unit">{reward.unit}</span>
                  </p>
                )}
                {Array.isArray(card.playdate) ? (
                  <p className="date-wrap">
                    {card.playdate.map((it, idx) => {
                      return (
                        <p className="date" key={idx}>
                          {it}
                        </p>
                      );
                    })}
                  </p>
                ) : (
                  <p className="date">{card.playdate}</p>
                )}
              </div>
              <div className="ticket-wrap">
                {card.nums.map((it: Nums, idx: number) => (
                  <div className="item" key={idx}>
                    <div className="ticket-tit-wrap">
                      <p className="">Game #{idx + 1}</p>
                      <div className="btn-wrap">
                        <span
                          className="btn material-symbols-rounded"
                          onClick={() =>
                            delTicket(card.idx, card.nums[idx].idx)
                          }
                        >
                          delete
                        </span>
                      </div>
                    </div>
                    <div className="picked-ball-wrap">
                      <div className="ball-wrap">
                        {it.num
                          .split(",")
                          .map(
                            (number: string, index: number, arr: string[]) => (
                              <div
                                key={index}
                                className={`ball ${
                                  index === arr.length - 1 ? isBall(spBall) : ""
                                }`}
                              >
                                {number}
                              </div>
                            )
                          )}
                      </div>
                      {ballEditOpen && (
                        <>
                          <div className="edit-ball-wrap">
                            <div className="ball-count">
                              <div className="tit-count-wrap">
                                <p className="tit">Ball</p>
                                <p className="count">
                                  <span>1</span>of 5
                                </p>
                              </div>
                            </div>
                            <div className="ball-wrap">
                              {[
                                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
                                15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                                27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
                                39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
                                51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62,
                                63, 64, 65, 66, 67, 68, 69, 70,
                              ].map((a, i) => {
                                return (
                                  <div
                                    className={`ball ${
                                      isPicked ? "picked" : ""
                                    }`}
                                    key={i}
                                    onClick={() => {
                                      setIsPicked(!isPicked);
                                    }}
                                  >
                                    {a}
                                  </div>
                                );
                              })}
                            </div>
                            <div
                              className="tit-count-wrap"
                              style={{ marginTop: "12px" }}
                            >
                              <p className="mega">USA Mega Lottery</p>
                              <p className="count">
                                <span>1</span>of 1
                              </p>
                            </div>
                            <div className="ball-wrap mega">
                              {[
                                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
                                15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
                              ].map((a, i) => {
                                return (
                                  <div
                                    className={`ball ${
                                      isPicked ? "picked" : ""
                                    }`}
                                    key={i}
                                    onClick={() => {
                                      setIsPicked(!isPicked);
                                    }}
                                  >
                                    {a}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="btn style01 md">Confirm</div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
                <div className="item multiplier">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={card.multiplier === "Y"}
                        disabled={stopMulti === true}
                        onClick={() => editCartType(card.idx, card.multiplier)}
                      />
                    }
                    label="Add multiplier $3.45 per game"
                    labelPlacement="start"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (card?.systemCode.includes("ST")) {
      const systemTag = card.systemCode.substring(2);
      const sysCode = card.systemCode;
      return (
        <div className={`item ${delTarget === item.idx ? "delete" : ""}`}>
          <div
            className={`top-area ${cartItemOpen && "open"}`}
            onClick={() => setCartItemOpen((prev) => !prev)}
          >
            <div className="game">
              <img src={isMode(card.productCode)} alt={card.productCode} />
              <div className="name-tag-wrap">
                <p className="name">{card.productName}</p>
                <p className="tag system">System {systemTag}</p>
                {card.multiplier === "Y" && (
                  <p className="tag multiplier">Multiplier</p>
                )}
                {card.subscription === "Y" && (
                  <p
                    className="tag subscription"
                    style={{ backgroundColor: "red" }}
                  >
                    Subscription
                  </p>
                )}
              </div>
            </div>
            <div className="num-price-wrap">
              <p className="num">{card.ticketQty} Games</p>
              <p className="price">$ {card.totalAmount}</p>
            </div>
            <div className="btn-wrap">
              <div
                className="btn style01 sm delete"
                onClick={(e) => {
                  e.stopPropagation();
                  delCartType(item.idx);
                }}
              >
                <span className="material-symbols-rounded">delete</span>
              </div>
              <span className="material-symbols-rounded arr">
                keyboard_arrow_down
              </span>
            </div>
          </div>

          <div className={`vertical-trans ${cartItemOpen ? "open" : "close"}`}>
            <div className="bottom-area">
              <div className="game">
                {findCardType(item.productCode) === 1 ? (
                  <p className="prize">Pending</p>
                ) : (
                  <p className="prize">
                    <span className="unit">{reward.mark} </span>
                    <span className="unit">{reward.amount} </span>
                    <span className="unit">{reward.unit}</span>
                  </p>
                )}
                {Array.isArray(card.playdate) ? (
                  card.playdate.map((it, idx) => (
                    <p key={idx} className="date">
                      {it}
                    </p>
                  ))
                ) : (
                  <p className="date">{card.playdate}</p>
                )}
              </div>

              <div className="ticket-wrap">
                {card.nums.map((it, idx) => (
                  <div className="item" key={idx}>
                    <div className="ticket-tit-wrap">
                      <p>Game #{idx + 1}</p>
                      <div className="btn-wrap">
                        <span
                          className="btn material-symbols-rounded"
                          onClick={() =>
                            delTicket(card.idx, card.nums[idx].idx)
                          }
                        >
                          delete
                        </span>
                      </div>
                    </div>

                    <div className="picked-ball-wrap">
                      <div className="ball-wrap">
                        {it.num.split(",").map((it, idx, arr: string[]) => (
                          <div
                            key={idx}
                            className={`ball ${
                              idx === arr.length - 1
                                ? isBall(card.productCode)
                                : ""
                            }`}
                          >
                            {it}
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* ---- show nums ---- */}
                    <ShowNums
                      nums={it.num}
                      code={card.productCode}
                      sysCode={sysCode}
                    />
                    {/* ---- show nums ---- */}
                  </div>
                ))}
                <div className="item multiplier">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={card.multiplier === "Y"}
                        disabled={stopMulti === true}
                        onClick={() => editCartType(card.idx, card.multiplier)}
                      />
                    }
                    label="Add multiplier $3.45 per game"
                    labelPlacement="start"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (card.systemCode === "PIC") {
      const sysCode = card.systemCode;
      return (
        <div className={`item ${delTarget === item.idx ? "delete" : ""}`}>
          <div
            className={`top-area ${cartItemOpen && "open"}`}
            onClick={() => {
              setCartItemOpen((prev) => !prev);
            }}
          >
            <div className="game">
              <img src={isMode(card.productCode)} alt={card.productCode} />
              <div className="name-tag-wrap">
                <p className="name">{card.productName}</p>
                <p className="tag one-less">Pick 1 less</p>
                {card.multiplier === "Y" && (
                  <p className="tag multiplier">Multiplier</p>
                )}
                {card.subscription === "Y" && (
                  <p
                    className="tag subscription"
                    style={{ backgroundColor: "red" }}
                  >
                    Subscription
                  </p>
                )}
              </div>
            </div>
            <div className="num-price-wrap">
              <p className="num">{card.ticketQty} Games</p>
              <p className="price">$ {card.totalAmount}</p>
            </div>
            <div className="btn-wrap">
              <div
                className="btn style01 sm delete"
                onClick={(e) => {
                  e.stopPropagation();
                  delCartType(item.idx);
                }}
              >
                <span className="material-symbols-rounded">delete</span>
              </div>
              <span className="material-symbols-rounded arr">
                keyboard_arrow_down
              </span>
            </div>
          </div>

          <div className={`vertical-trans ${cartItemOpen ? "open" : "close"}`}>
            <div className="bottom-area">
              <div className="game">
                {findCardType(item.productCode) === 1 ? (
                  <p className="prize">Pending</p>
                ) : (
                  <p className="prize">
                    <span className="unit">{reward.mark} </span>
                    <span className="unit">{reward.amount} </span>
                    <span className="unit">{reward.unit}</span>
                  </p>
                )}
                {Array.isArray(card.playdate) ? (
                  card.playdate.map((it, idx) => (
                    <p key={idx} className="date">
                      {it}
                    </p>
                  ))
                ) : (
                  <p className="date">{card.playdate}</p>
                )}
              </div>

              <div className="ticket-wrap">
                {card.nums.map((it: Nums, idx: number) => (
                  <div className="item" key={idx}>
                    <div className="ticket-tit-wrap">
                      <p>Game #{idx + 1}</p>
                      <div className="btn-wrap">
                        <span
                          className="btn material-symbols-rounded"
                          onClick={() =>
                            delTicket(card.idx, card.nums[idx].idx)
                          }
                        >
                          delete
                        </span>
                      </div>
                    </div>
                    <div className="picked-ball-wrap">
                      <div className="ball-wrap">
                        {it.num.split(",").map((it, idx, arr: string[]) => (
                          <div
                            key={idx}
                            className={`ball ${
                              idx === arr.length - 1
                                ? isBall(card.productCode)
                                : ""
                            }`}
                          >
                            {it}
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* ---- show nums ---- */}
                    <ShowNums
                      nums={it.num}
                      code={card.productCode}
                      sysCode={sysCode}
                    />
                    {/* ---- show nums ---- */}
                  </div>
                ))}
                <div className="item multiplier">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={card.multiplier === "Y"}
                        disabled={stopMulti === true}
                        onClick={() => editCartType(card.idx, card.multiplier)}
                      />
                    }
                    label="Add multiplier $3.45 per game"
                    labelPlacement="start"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (card.systemCode === "GUA") {
      const sysCode = card.systemCode;
      return (
        <div className={`item ${delTarget === item.idx ? "delete" : ""}`}>
          <div
            className={`top-area ${cartItemOpen && "open"}`}
            onClick={() => {
              setCartItemOpen((prev) => !prev);
            }}
          >
            <div className="game">
              <img src={isMode(card.productCode)} alt={card.productCode} />
              <div className="name-tag-wrap">
                <p className="name">{card.productName}</p>
                <p className="tag guaranteed">Guaranteed</p>
                {card.multiplier === "Y" && (
                  <p className="tag multiplier">Multiplier</p>
                )}
                {card.subscription === "Y" && (
                  <p
                    className="tag subscription"
                    style={{ backgroundColor: "red" }}
                  >
                    Subscription
                  </p>
                )}
              </div>
            </div>
            <div className="num-price-wrap">
              <p className="num">{card.ticketQty} Games</p>
              <p className="price">$ {card.totalAmount}</p>
            </div>
            <div className="btn-wrap">
              {/* <div
                className="btn style01 sm"
                onClick={() => {
                  setCartItemOpen(!cartItemOpen);
                }}
              >
                Edit
              </div> */}
              <div
                className="btn style01 sm delete"
                onClick={(e) => {
                  e.stopPropagation();
                  delCartType(item.idx);
                }}
              >
                <span className="material-symbols-rounded">delete</span>
              </div>
              <span className="material-symbols-rounded arr">
                keyboard_arrow_down
              </span>
            </div>
          </div>

          <div className={`vertical-trans ${cartItemOpen ? "open" : "close"}`}>
            <div className="bottom-area">
              <div className="game">
                {findCardType(item.productCode) === 1 ? (
                  <p className="prize">Pending</p>
                ) : (
                  <p className="prize">
                    <span className="unit">{reward.mark} </span>
                    <span className="unit">{reward.amount} </span>
                    <span className="unit">{reward.unit}</span>
                  </p>
                )}
                {Array.isArray(card.playdate) ? (
                  card.playdate.map((it, idx) => (
                    <p key={idx} className="date">
                      {it}
                    </p>
                  ))
                ) : (
                  <p className="date">{card.playdate}</p>
                )}
              </div>

              <div className="ticket-wrap">
                {card.nums.map((it: Nums, idx: number) => (
                  <div className="item" key={idx}>
                    <div className="ticket-tit-wrap">
                      <p>Game #{idx + 1}</p>
                      <div className="btn-wrap">
                        <span
                          className="btn material-symbols-rounded"
                          onClick={() => {
                            delTicket(card.idx, card.nums[idx].idx);
                          }}
                        >
                          delete
                        </span>
                      </div>
                    </div>
                    <div className="picked-ball-wrap">
                      <div className="ball-wrap">
                        {it.num.split(",").map((it, idx, arr: string[]) => (
                          <div
                            key={idx}
                            className={`ball ${
                              idx === arr.length - 1 ? "guaranteed" : ""
                            }`}
                          >
                            {it}
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* ---- show nums ---- */}
                    <ShowNums
                      nums={it.num}
                      code={card.productCode}
                      sysCode={sysCode}
                    />
                    {/* ---- show nums ---- */}
                  </div>
                ))}

                <div className="item multiplier">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={card.multiplier === "Y"}
                        disabled={stopMulti === true}
                        onClick={() => editCartType(card.idx, card.multiplier)}
                      />
                    }
                    label="Add multiplier $3.45 per game"
                    labelPlacement="start"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {loading ? (
        <Skeleton
          animation="pulse"
          variant="rounded"
          style={{
            width: "100%",
            height: 70,
            margin: "0 auto",
            marginTop: "10px",
            borderRadius: "14px",
          }}
        />
      ) : (
        isCardType(item)
      )}
    </>
  );
};

export default React.memo(CartItem);
