const Terms = () => {
  return (
    <div className="sub privacy">
      <div className="sub-top-area">
        <h3 className="tit">Terms & Conditions of Use</h3>
      </div>

      <section>
        <p>
          Global Players Network Pty Ltd (The Licensee) claims copyright and
          ownership of the intellectual property for the contents of this
          website. Any unauthorised copying or other misuse of this site or the
          contents will be prosecuted.
          <br />
          <br />
          Welcome to The Lottery Cluster. Please ensure you read the following
          Terms and Conditions before using this website,
          www.lotteryoffice.com.au (The Lottery Office) together with any
          sub-domains, content, marks and services to ensure you are aware of
          your legal rights and obligations with respect to The Lottery Office
          and the services it provides. By using the website, including viewing
          the content, you agree to the Terms and Conditions.
          <br />
          <br />
          The Terms and Conditions of the website apply to both Registered
          Players and visitors to the website.
          <br />
          <br />
          The Lottery Cluster is operated by Global Players Network Pty Ltd,
          Darwin, Northern Territory, Australia, 0821, and licensed and
          regulated by the Northern Territory Government of Australia. For the
          purposes of these Terms and Conditions The Lottery Office includes all
          registered businesses and companies owned by Global Players Network
          Pty Ltd and approved for this purpose by written Direction of the
          Director of Gaming Control, Department of Justice of the Northern
          Territory. Our licence number is IGL1001 which can be confirmed at or
          by calling Licensing NT on +61 8 8999 1800. The Licensing contact web
          page can be found at .
          <br />
          <br />
          We reserve the right, in our absolute discretion, to change these
          website Terms and Conditions at any time and The Lottery Office will
          post any Government approved changes to the Terms and Conditions on
          the website. The approved new version of these Terms and Conditions
          will be effective immediately upon posting to The Lottery Office
          website and the next time a Registered Player logs on to the website
          they will receive a notification alert advising that the Terms and
          Conditions have been updated and continued use of the services
          provided on the website acknowledges acceptance of the changed Terms
          and Conditions.
          <br />
          <br />
          We hereby grant permission to access and use the website, provided you
          comply with these Terms and Conditions and applicable law and do not
          copy, distribute or modify any part of the website without prior
          written authorisation from The Lottery Office nor use, modify, create
          derivative works of, transfer (by sale, resale, licence, sub-licence,
          download or otherwise), reproduce, distribute, display or disclose
          content, except as explicitly authorised; nor disrupt servers or
          networks connected to the website; nor use or launch any automated
          system (including without limitation, "robots" and "spiders") to
          access and/or use the website.
          <br />
          <br />
          The Lottery Cluster reserves the right at any time, in its absolute
          discretion, to deny, suspend or terminate your Online Account or your
          access to the website, or to shut your Online Account or the website
          down, cancel or suspend the Online Account or the website, for any
          reason whatsoever, including, without any limitation, for a breach of
          these Terms and Conditions or any instruction or rule appearing on the
          website.
          <br />
          <br />
          Furthermore you agree not to use communications systems within the
          website to send unsolicited or unauthorised commercial communications;
          nor use the website for any fraudulent or deceptive purposes; and/or
          circumvent, disable or otherwise interfere with security-related
          features of the website or features that restrict or prevent use or
          copying of any content or that enforce limitations on use of the
          website. We reserve all rights not expressly granted in and to the
          website.
        </p>
      </section>
    </div>
  );
};

export default Terms;
