import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

interface Ball {
  white: number;
  special: number;
}
interface BallSet {
  [key: string]: Ball;
  mega: Ball;
  power: Ball;
}

// constant
const BallSet: BallSet = {
  mega: {
    white: 70,
    special: 25,
  },
  power: {
    white: 69,
    special: 26,
  },
};
interface Nums {
  idx: number;
  method: string;
  num: string;
}

interface GameData {
  idx: number;
  productCode: string;
  methodCode: string;
  systemCode: string;
  ticketQty: number;
  nums: Nums[];
}
interface RemoteCardProps {
  data: GameData;
  gameType: string;
  idx: number;
  isOpen: boolean;
  isActive: number;
  gameLength: number;
  isAuto: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handlePick: (number: string, isSpecial: boolean) => void;
  handleDelete: (target: number) => void;
  handleClear: (target: number) => void;
  quickPlay: (target: number) => void;
}

const BarGameCard = ({
  idx,
  data,
  gameType,
  isOpen,
  isAuto,
  isActive,
  gameLength,
  setIsOpen,
  handlePick,
  handleDelete,
  handleClear,
  quickPlay,
}: RemoteCardProps) => {
  const [wBall, setWball] = useState<string[]>();
  const [sBall, setSball] = useState<string>();

  useEffect(() => {
    const w = data.nums[0].num.split(",").slice(0, -1);
    const s = data.nums[0].num.split(",").pop();

    setWball(w);
    setSball(s);
  }, [data.nums[0].num]);

  const search = window.location.search.slice(1, location.search.length);
  const split = search.split("&");
  const type = split[0] === "" ? "mega" : split[0].split("=")[1];

  const ballMaker = () => {
    return Array.from({ length: type === "mega" ? 70 : 69 }, (_, i) => {
      const ballNum = i + 1;
      return (
        <div
          key={ballNum}
          onClick={() => handlePick(String(ballNum), false)}
          className={`ball ${wBall?.includes(String(ballNum)) && "picked"}`}
        >
          {ballNum}
        </div>
      );
    });
  };

  const specialMaker = () => {
    return Array.from({ length: type === "mega" ? 25 : 26 }, (_, i) => {
      const ballNum = i + 1;
      return (
        <div
          key={ballNum}
          onClick={() => handlePick(String(ballNum), true)}
          className={`ball ${sBall === String(ballNum) && "picked"}`}
        >
          {ballNum}
        </div>
      );
    });
  };

  return (
    <div className="item">
      <div className="ticket-tit-wrap">
        <p>Game #{idx + 1}</p>
        <div className="btn-wrap">
          <span
            className={`btn material-symbols-rounded ${isAuto && "disabled"}`}
            onClick={() => quickPlay(data.idx)}
          >
            bolt
          </span>
          <span
            className={`btn material-symbols-rounded ${isAuto && "disabled"}`}
            onClick={() => handleClear(data.idx)}
          >
            mop
          </span>
          {gameLength > 1 && (
            <span
              className={`btn material-symbols-rounded ${isAuto && "disabled"}`}
              onClick={() => handleDelete(data.idx)}
            >
              delete
            </span>
          )}
        </div>
      </div>
      <div className="picked-ball-wrap">
        <div className="ball-wrap" onClick={() => setIsOpen((prev) => !prev)}>
          {wBall?.map((it, index) => (
            <div key={index} className="ball">
              {it !== "0" && it}
            </div>
          ))}
          <div className={`ball ${gameType}`}>{sBall !== "0" && sBall}</div>
          <span className={`material-symbols-rounded ${isOpen && "open"}`}>
            keyboard_arrow_down
          </span>
        </div>
        <div className={`vertical-trans ${isOpen ? "open" : "close"}`}>
          <div className="edit-ball-wrap">
            <div className="ball-count">
              <div className="tit-count-wrap">
                <p>Ball</p>
                <p>
                  <span>{wBall?.filter((it) => it !== "0")?.length}</span> of{" "}
                  {wBall?.length}
                </p>
              </div>
            </div>
            <div className="ball-wrap">{ballMaker()}</div>

            {!sBall?.includes("G") && (
              <>
                <div
                  className={`tit-count-wrap ${
                    gameType === "mega" ? "mega" : "power"
                  }`}
                >
                  <p>Mega ball</p>
                  <p>
                    <span>{sBall !== "0" ? 1 : 0}</span> of 1
                  </p>
                </div>
                <div className={`ball-wrap ${type}`}>{specialMaker()}</div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarGameCard;
