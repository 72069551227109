import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { reset, update } from "../../../tools/zustandUpdate";
import axios from "axios";
import Swal from "sweetalert2";
import User from "../../../tools/userInfo";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

// type
interface GameInfo {
  drawnum: number;
  jackpot: number;
  playdate: string;
  orderTime: string;
}

interface FavProps {
  type: string;
  setLogin: Dispatch<SetStateAction<boolean>>;
  gameInfo: GameInfo;
}

const Favorites = ({ type, gameInfo, setLogin }: FavProps) => {
  // tools
  const { api, token, info, setTotalInfo } = User();
  // stat
  const [test, setTest] = useState<any>();

  /** get Fav */
  const getFav = () => {
    axios
      .get(`${api}/favorite`, {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          Authorization: "bearer " + token,
        },
      })
      .then((res) => setTest(res.data.data))
      .catch((error) => console.error(error));
  };

  /** remove Fav */
  const removeFav = (idx: string) => {
    axios
      .delete(`${api}/favorite/${idx}`, {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          Authorization: "bearer " + token,
        },
      })
      .then((res) => {
        console.log(res);
        getFav();
      })
      .catch((error) => console.error(error));
  };

  // useEffect
  useEffect(() => {
    if (!token) {
      Swal.fire({
        icon: "warning",
        title: "Members Only",
        text: "This method is only for Lottery Cluster members",
        allowOutsideClick: false,
      });
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    } else {
      getFav();
    }
  }, []);

  const [ballEditOpen, setBallEditOpen] = useState(false);

  return (
    <div className="favourite">
      {/* favourite 없을때 */}
      <div className="fav-nodata">
        <p>
          Got any favourite numbers you want to play with?
          <br />
          Create your Favourite numbers here.
        </p>
        <div className="btn style01 md spc add-fav">
          <span className="material-symbols-rounded">add_circle</span>Add my
          favourite
        </div>
      </div>
      {/* favourite 없을때 - end */}

      <div className="fav-wrap">
        <div className="item">
          <h4 className="tit">Select mode</h4>
          <FormControl fullWidth>
            <InputLabel id="game-mode">Select game mode</InputLabel>
            <Select
              labelId="game-mode"
              id="game-mode"
              label="Select game tmode"
              value={1}
            >
              <MenuItem value="1">Standard</MenuItem>
              <MenuItem value="2">Guranteed</MenuItem>
              <MenuItem value="3">System6</MenuItem>
              <MenuItem value="4">System7</MenuItem>
              <MenuItem value="5">System8</MenuItem>
              <MenuItem value="6">System9</MenuItem>
              <MenuItem value="7">System10</MenuItem>
              <MenuItem value="8">Pick 1 less</MenuItem>
            </Select>
          </FormControl>
          <div className="ticket-wrap">
            <div className="ticket-tit-wrap">
              <p>Game #1</p>
              <div className="btn-wrap">
                <span className="btn material-symbols-rounded">bolt</span>
                <span className="btn material-symbols-rounded">mop</span>

                <span className="btn material-symbols-rounded">delete</span>
              </div>
            </div>

            <div className="picked-ball-wrap">
              <div
                className="ball-wrap"
                onClick={() => setBallEditOpen(!ballEditOpen)}
              >
                <div className="ball"></div>

                <div className="ball mega"></div>
                <span
                  className="material-symbols-rounded"
                  onClick={() => {
                    setBallEditOpen(true);
                  }}
                >
                  keyboard_arrow_down
                </span>
              </div>

              {ballEditOpen && (
                <div className="edit-ball-wrap">
                  <div className="ball-count">
                    <div className="tit-count-wrap">
                      <p className="tit">Ball</p>
                      <p className="count">
                        <span>1</span> of 5
                      </p>
                    </div>
                  </div>

                  <div className="ball-wrap"></div>

                  <div className="tit-count-wrap" style={{ marginTop: "12px" }}>
                    <p className="mega">Mega ball</p>
                    <p className="count">
                      <span>0</span> of 1
                    </p>
                  </div>

                  <div
                    className={`ball-wrap ${
                      type === "mega" ? "mega" : "power"
                    }`}
                  ></div>
                </div>
              )}
            </div>
          </div>
          <div className="ticket-wrap">
            <div className="ticket-tit-wrap">
              <p>Game #1</p>
              <div className="btn-wrap">
                <span className="btn material-symbols-rounded">bolt</span>
                <span className="btn material-symbols-rounded">mop</span>

                <span className="btn material-symbols-rounded">delete</span>
              </div>
            </div>

            <div className="picked-ball-wrap">
              <div
                className="ball-wrap"
                onClick={() => setBallEditOpen(!ballEditOpen)}
              >
                <div className="ball"></div>

                <div className="ball mega"></div>
                <span
                  className="material-symbols-rounded"
                  onClick={() => {
                    setBallEditOpen(true);
                  }}
                >
                  keyboard_arrow_down
                </span>
              </div>

              {ballEditOpen && (
                <div className="edit-ball-wrap">
                  <div className="ball-count">
                    <div className="tit-count-wrap">
                      <p className="tit">Ball</p>
                      <p className="count">
                        <span>1</span> of 5
                      </p>
                    </div>
                  </div>

                  <div className="ball-wrap"></div>

                  <div className="tit-count-wrap" style={{ marginTop: "12px" }}>
                    <p className="mega">Mega ball</p>
                    <p className="count">
                      <span>0</span> of 1
                    </p>
                  </div>

                  <div
                    className={`ball-wrap ${
                      type === "mega" ? "mega" : "power"
                    }`}
                  ></div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="btn-wrap">
          <div className="btn style01 md">
            <span className="material-symbols-rounded">add</span>Add a game
          </div>
          <div className="btn style01 md">
            <span className="material-symbols-rounded">shopping_cart</span>Save
            & add to cart
          </div>
        </div>
      </div>
      <div className="btn style01 md spc add-fav">
        <span className="material-symbols-rounded">add_circle</span>Add my
        favourite
      </div>
    </div>
  );
};

export default Favorites;
