interface Prize {
  mark: string;
  amount: number;
  unit: string;
}

const amountMaker = (amount: number) => {
  const inmil = Number((amount / 1000000).toFixed(1));
  let final = 0;
  let unit = "Millions";

  if (inmil >= 1000) {
    const inbil = inmil / 1000;
    final = Math.floor(inbil * 100) / 100;
    unit = "Billions";
  } else if (inmil > 0) {
    final = inmil;
  }

  const prize: Prize = {
    mark: "$",
    amount: final,
    unit: unit,
  };

  return prize;
};

export default amountMaker;

// const amountMaker = (amount: number) => {
//   const inmil = Number((amount / 1000000).toFixed(1));
//   let finalAmount = '';

//   if (inmil >= 1000) {
//     const inbil = inmil / 1000;
//     finalAmount = `$ ${inbil.toFixed(2)} Billion`;
//   } else if (inmil > 0) {
//     finalAmount = `$ ${inmil.toFixed(2)} Million`;
//   } else {
//     finalAmount = `$ ${amount.toLocaleString()}`;
//   }

//   return finalAmount;
// };

// export default amountMaker;
