import { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/material/Switch";

// import CountDown from "../../components/common/countdown";
import Lottie from "lottie-react";

// img
import StarsMega from "../../img/sub/stars-mega.json";
import Mega from "../../img/common/mega-ball-logo.png";
import Power from "../../img/common/power-ball-logo.png";
import MegaLogo from "../../img/common/mega-white-logo.svg";
import PowerLogo from "../../img/common/power-white-logo.svg";

const ResultTest = () => {
  const [value, setValue] = useState<Dayjs | null>(dayjs("2022-04-17"));
  const [detailOpen, setDetailOpen] = useState(false);
  const [game, setGame] = useState("power");

  return (
    <div className="sub result-test">
      {/* Game Top Banner */}
      <div className="game-top-area power">
        <div className="game-info-area">
          <p className="name">Powerball</p>
          <p className="prize">
            <span className="unit">$ </span>
            <span className="num">180</span>
            <span className="unit"> Million</span>
          </p>
          <p className="time">Close in : 12 hrs, 6 mins</p>
        </div>
        <div className="img-area">
          <Lottie animationData={StarsMega} className="stars" loop={true} />
          <img src={MegaLogo} alt="Powerball" className="logo" />
        </div>
      </div>

      <div className="result-wrap">
        <div className="step filter">
          <div className="item">
            <h4 className="tit">Sort by Game</h4>
            <FormControl fullWidth>
              <InputLabel id="sort-game">Select Game</InputLabel>
              <Select labelId="sort-game" id="Powerball" label="Select Game">
                <MenuItem value="power">Powerball</MenuItem>
                <MenuItem value="mega">Mega Millions</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="item">
            <h4 className="tit">Search by Date Range</h4>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="datepicker-wrap">
                <DatePicker
                  label="Start Date"
                  defaultValue={dayjs("2022-04-17")}
                />

                <DatePicker
                  label="End Date"
                  value={value}
                  onChange={(newValue) => setValue(newValue)}
                />
              </div>
            </LocalizationProvider>
          </div>
        </div>

        <div className="step result">
          <div className="item">
            <div className="game-info-area">
              <img src={Power} alt="Powerball" />
              <div className="item-section">
                <div className="name-tag-wrap">
                  <p className="name power">Powerball</p>
                  <p className="tag multiplier">Multiplier 3x</p>
                </div>
                <p className="prize">
                  <span className="unit">$ </span>
                  <span className="num">185</span>
                  <span className="unit"> Million</span>
                </p>

                <p className="time">Draw Date : 20 Oct 2024</p>
              </div>
              <div className="item-section">
                <h5 className="tit">Winning Numbers</h5>
                <div className="ball-wrap">
                  <div className="ball">1</div>
                  <div className="ball">2</div>
                  <div className="ball">3</div>
                  <div className="ball">4</div>
                  <div className="ball">5</div>
                  <div className="ball power">6</div>
                </div>
              </div>
              <div className="item-section">
                <div
                  className={`btn style01 md ${detailOpen && "open"}`}
                  onClick={() => {
                    setDetailOpen(!detailOpen);
                  }}
                >
                  {detailOpen ? "View Less" : "View More"}
                  <span className="material-symbols-rounded">
                    keyboard_arrow_down
                  </span>
                </div>
              </div>
            </div>

            <div className={`vertical-trans ${detailOpen ? "open" : "close"}`}>
              <div className="show-detail">
                <p className="prize">AUD 588,121,122</p>
                <p className="multi">Multiplier 3X</p>
                <FormControlLabel
                  control={<Switch checked />}
                  label="Apply Multiplier 3X ?"
                  labelPlacement="start"
                />

                <div className="table-wrap">
                  <table>
                    <tr>
                      <th>Division</th>
                      <th>Winning Combination</th>
                      <th>Prize</th>
                      <th>Winners</th>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>
                        <div className="ball-wrap">
                          <div className="ball"></div>
                          <div className="ball"></div>
                          <div className="ball"></div>
                          <div className="ball"></div>
                          <div className="ball"></div>
                          <div className="ball power"></div>
                        </div>
                      </td>
                      <td>AUD 180,000,000</td>
                      <td>0</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="item">
            <div className="game-info-area">
              <img src={Power} alt="Powerball" />
              <div className="item-section">
                <div className="name-tag-wrap">
                  <p className="name power">Powerball</p>
                  <p className="tag multiplier">Multiplier 3x</p>
                </div>
                <p className="prize">
                  <span className="unit">$ </span>
                  <span className="num">185</span>
                  <span className="unit"> Million</span>
                </p>

                <p className="time">Draw Date : 20 Oct 2024</p>
              </div>
              <div className="item-section">
                <h5 className="tit">Winning Numbers</h5>
                <div className="ball-wrap">
                  <div className="ball">1</div>
                  <div className="ball">2</div>
                  <div className="ball">3</div>
                  <div className="ball">4</div>
                  <div className="ball">5</div>
                  <div className="ball power">6</div>
                </div>
              </div>
              <div className="item-section">
                <div
                  className={`btn style01 md ${detailOpen && "open"}`}
                  onClick={() => {
                    setDetailOpen(!detailOpen);
                  }}
                >
                  {detailOpen ? "View Less" : "View More"}
                  <span className="material-symbols-rounded">
                    keyboard_arrow_down
                  </span>
                </div>
              </div>
            </div>

            <div className={`vertical-trans ${detailOpen ? "open" : "close"}`}>
              <div className="show-detail">
                <p className="prize">AUD 588,121,122</p>
                <p className="multi">Multiplier 3X</p>
                <FormControlLabel
                  control={<Switch checked />}
                  label="Apply Multiplier 3X ?"
                  labelPlacement="start"
                />

                <div className="table-wrap">
                  <table>
                    <tr>
                      <th>Division</th>
                      <th>Winning Combination</th>
                      <th>Prize</th>
                      <th>Winners</th>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>
                        <div className="ball-wrap">
                          <div className="ball"></div>
                          <div className="ball"></div>
                          <div className="ball"></div>
                          <div className="ball"></div>
                          <div className="ball"></div>
                          <div className="ball power"></div>
                        </div>
                      </td>
                      <td>AUD 180,000,000</td>
                      <td>0</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="btn style01 md load-more">Load More</div>
        </div>
      </div>
    </div>
  );
};

export default ResultTest;
