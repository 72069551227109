const Comp2 = ({ onNext }: { onNext: () => void }) => {
  return (
    <div className="comp">
      <p>Comp2</p>
      <div className="btn style01 md" onClick={onNext}>
        Next
      </div>
    </div>
  );
};

export default Comp2;
