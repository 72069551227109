import React, { useState } from "react";
import { FaRegEdit } from "react-icons/fa";

// type
export interface Ball {
  white: number;
  special: number;
}

export interface BallSet {
  [key: string]: Ball;
  mega: Ball;
  power: Ball;
}

const BallSet: BallSet = {
  mega: {
    white: 70,
    special: 25,
  },
  power: {
    white: 69,
    special: 26,
  },
};

const FavTestCard = ({ item, idx, type, method, system }: any) => {
  const [editOpen, setEditOpen] = useState<boolean>(false);

  const isType = type === "PB" ? "power" : "mega";

  // 번호 배열
  const balls = item.num.split(",");
  // 화이트볼 (PIC은 G포함됨)
  const white = balls.slice(0, -1);
  // 스폐셜 (맨마지막 볼)
  const special = balls[balls.length - 1];

  const ballMaker = () => {
    return Array.from({ length: isType === "mega" ? 70 : 69 }, (_, i) => {
      const ballNum = i + 1;
      return (
        <div
          key={ballNum}
          className={`border text-center p-1 ${
            white.includes(String(ballNum)) && "bg-white text-black"
          }`}
        >
          {ballNum}
        </div>
      );
    });
  };

  const specialMaker = (system: string) => {
    if (system === "GUA") {
      return;
    } else {
      return Array.from({ length: isType === "mega" ? 25 : 26 }, (_, i) => {
        const ballNum = i + 1;
        return (
          <div
            key={ballNum}
            className={`border text-center p-1 ${
              isType === "power"
                ? "border-red-300 text-red-300"
                : "border-yellow-300 text-yellow-300"
            } ${String(ballNum) === special ? "bg-white" : ""}`}
          >
            {ballNum}
          </div>
        );
      });
    }
  };

  return (
    <>
      <div key={item.idx} className="flex gap-2 border p-2 justify-between">
        <b>{idx + 1} Game</b>
        <div className="flex gap-2">
          {item.num.split(",").map((it: any, idx: number) => (
            <div
              key={idx}
              className={`border rounded-full w-6 h-6 text-center text-sm ${
                idx === item.num.split(",").length - 1 && "bg-white text-black"
              }`}
            >
              {it}
            </div>
          ))}
        </div>
        <FaRegEdit
          className="cursor-pointer"
          onClick={() => setEditOpen((prev) => !prev)}
        />
      </div>
      <div>
        {editOpen && (
          <div className="border p-2 flex justify-center">
            <div>
              <div className="grid grid-cols-5 gap-1">{ballMaker()}</div>
              <div className="grid grid-cols-5 gap-1 mt-5">
                {specialMaker(system)}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FavTestCard;
