// img
import NoDataImg from "./../../img/sub/nodata.svg";

const NoData = () => {
  return (
    <div className="noDataBox">
      <h3>
        <span className="material-symbols-rounded">info</span>Data Not Available
      </h3>
      <p>No data or it may be a temporary Internet connection problem.</p>
      <button
        onClick={() => window.location.reload()}
        className="btn style01 sm"
      >
        Refresh
      </button>
    </div>
  );
};

export default NoData;
