import { useState, useEffect } from "react";
import Comp1 from "./design_comp1";
import Comp2 from "./design_comp2";
import Comp3 from "./design_comp3";

const Design = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [currentComp, setCurrentComp] = useState(1);

  const handleNext = () => {
    setCurrentComp((prev) => (prev < 3 ? prev + 1 : 1)); // 컴포넌트 순환
  };

  return (
    <div className={`design ${isMobile && "mobile"}`}>
      {isMobile ? (
        <>
          {/* MOBILE */}
          {currentComp === 1 && <Comp1 onNext={handleNext} />}
          {currentComp === 2 && <Comp2 onNext={handleNext} />}
          {currentComp === 3 && <Comp3 />}
        </>
      ) : (
        <>
          {/* PC */}
          <Comp1 onNext={handleNext} />
          <Comp2 onNext={handleNext} />
          <Comp3 />
        </>
      )}

      <div
        className="btn style01 md"
        style={{ margin: "24px 0" }}
        onClick={() => {
          setIsMobile(!isMobile);
          setCurrentComp(1);
        }}
      >
        Change view
      </div>
    </div>
  );
};

export default Design;
