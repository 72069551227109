import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { reset, update } from "../tools/zustandUpdate";
import axios from "axios";
import Swal from "sweetalert2";
import User from "../tools/userInfo";
import { customAlphabet } from "nanoid";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FavItem from "../components/fav/favItem";
import { error } from "console";
import createAxiosInstance from "../api/axiosConfig";

// for

// type
interface GameInfo {
  drawnum: number;
  jackpot: number;
  playdate: string;
  orderTime: string;
}

interface FavNums {
  idx: number;
  method: string;
  num: string;
}

export interface FavData {
  idx: number;
  productCode: string;
  methodCode: string;
  systemCode: string;
  ticketQty: number;
  nums: FavNums[];
  isSaved?: string;
  isNew?: boolean;
}

export interface FavMenuInfo {
  idx: number;
  name: string;
  code: string;
  description: string;
  regular: number;
  special: number;
  games: number;
  price: string;
  optionPrice: string;
}

interface FavProps {
  type: string;
  setLogin: Dispatch<SetStateAction<boolean>>;
  gameInfo: GameInfo;
}

const Favorites = ({ type, gameInfo, setLogin }: FavProps) => {
  // tools
  const { api, token, info, setTotalInfo } = User();
  // stat
  const [favList, setFavList] = useState<FavData[]>();
  const [compare, setCompare] = useState<FavData[] | undefined>();
  const [selectMenu, setSelectMenu] = useState<FavMenuInfo[]>();
  const [activeEdit, setActiveEdit] = useState<number | null>(null);

  const axiosInstance = createAxiosInstance(api, token);
  const isType = type === "mega" ? "mm" : "pb";

  /** get Fav */
  const getFav = () => {
    axiosInstance
      .get(`/favorite/${isType}`)
      .then((res) => {
        setFavList(res.data.data);
        setCompare(res.data.data);
      })
      .catch((error) => console.error(error));
  };

  /** remove Fav */
  const removeFav = (idx: string) => {
    Swal.fire({
      icon: "question",
      title: "Remove from favorites?",
      showCancelButton: true,
      confirmButtonText: " Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#af7af1",
      cancelButtonColor: "#876aab",
    }).then((result) => {
      if (result.isConfirmed) {
        const targetItem = favList?.find((it) => it.idx === Number(idx));

        if (targetItem?.isSaved === "N") {
          const updatedFavList = favList?.filter(
            (it) => it.idx !== Number(idx)
          );
          setFavList(updatedFavList);
          setActiveEdit(null);
        } else {
          axiosInstance
            .delete(`/favorite/${idx}`)
            .then((res) => {
              setActiveEdit(null);
              getFav();
            })
            .catch((error) => console.error(error));
        }
      }
    });
  };

  const selectCreator = (select: FavMenuInfo) => {
    if (activeEdit !== null) {
      Swal.fire({
        icon: "warning",
        title: "Another card is being edited.",
        text: "Please save it before proceeding.",
        confirmButtonColor: "#af7af1",
        cancelButtonColor: "#876aab",
      });
      return;
    }

    switch (select.code) {
      case "CHN":
        addGame(select);
        break;
      case "GUA":
        addGame(select);
        break;
      case "ST6":
        addGame(select);
        break;
      case "ST7":
        addGame(select);
        break;
      case "ST8":
        addGame(select);
        break;
      case "ST9":
        addGame(select);
        break;
      case "STX":
        addGame(select);
        break;
      case "PIC":
        addGame(select);
        break;
    }
  };

  const addGame = (select: FavMenuInfo) => {
    const randomKeyNum = customAlphabet("1234567890", 12);
    const newNums =
      select.code === "GUA"
        ? "0,0,0,0,0,G"
        : select.code === "PIC"
        ? "0,0,0,0,G,0"
        : select.code === "CHN"
        ? "0,0,0,0,0,0"
        : select.code.includes("ST")
        ? Array(select.regular + select.special)
            .fill("0")
            .join(",")
        : "";

    const game = {
      isSaved: "N",
      idx: Number(randomKeyNum()),
      productCode: isType.toUpperCase(),
      methodCode: select.code === "CHN" ? "STD" : "SYS",
      systemCode: select.code,
      ticketQty: select.games,
      isNew: true,
      nums: [
        {
          idx: Number(randomKeyNum()),
          method: "M",
          num: newNums,
        },
      ],
    };

    setActiveEdit(game.idx);

    setFavList((prevList) => {
      const updatedList = prevList ? [...prevList, game] : [game];
      return updatedList;
    });
  };

  // useEffect
  useEffect(() => {
    // TODO: 구매 페이지 전체에서 token 값을 확인해서 favorite에서만 체크하도록 임시 조치함
    const urlParams = new URLSearchParams(window.location.search);
    const hasFParam = urlParams.has("F");

    if (hasFParam && !token) {
      Swal.fire({
        icon: "warning",
        title: "Members Only",
        text: "This method is only for Lottery Cluster members",
        confirmButtonColor: "#af7af1",
        cancelButtonColor: "#876aab",
        allowOutsideClick: false,
      });
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    } else {
      getFav();
      axiosInstance
        .get(`/product/favorite/${isType}`)
        .then((res) => setSelectMenu(res.data.data))
        .catch((error) => console.error(error));
    }
  }, []);

  return (
    <div className="favourite">
      {/* favItem comp */}

      {favList?.map((it, idx) => (
        <FavItem
          key={it.idx}
          idx={idx}
          type={type}
          data={it}
          removeFav={removeFav}
          getFav={getFav}
          setFavList={setFavList}
          compare={compare}
          selectMenu={selectMenu}
          activeEdit={activeEdit}
          setActiveEdit={setActiveEdit}
        />
      ))}

      {/* fav 없을 때 */}
      {!favList ||
        (favList.length === 0 && (
          <div className="fav-nodata">
            <p>
              Got any favourite numbers you want to play with?
              <br />
              Create your Favourite numbers here.
            </p>
            {/* <div className="btn style01 md spc add-fav">
              <span className="material-symbols-rounded">add_circle</span>Add my
              favourite
            </div> */}
          </div>
        ))}

      <div className="fav-add-wrap">
        <span className="material-symbols-rounded add">add</span>
        <div className="step">
          <h4 className="tit">Select to Add Favourites</h4>
          <FormControl fullWidth>
            <InputLabel id="game-mode">Lottery Options</InputLabel>
            <Select labelId="game-mode" id="game-mode" label="Lottery Options">
              {selectMenu?.map((it) => (
                <MenuItem
                  key={it.idx}
                  value={it.idx}
                  onClick={() => selectCreator(it)}
                >
                  {it.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <div className="btn style01 md spc add-fav">
          <span className="material-symbols-rounded">add_circle</span>Add my
          favourite
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Favorites;
