import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import IconButton from "@mui/material/IconButton";
import RadioGroup from "@mui/material/RadioGroup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// img
import Google from "../img/common/google-ico.svg";
import Facebook from "../img/common/facebook-ico.svg";
import Apple from "../img/common/apple-ico.svg";

// components

// tools
import User from "../tools/userInfo";
import NumberOnly from "../tools/numberOnly";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { Dayjs } from "dayjs";
import { OutlinedInput } from "@mui/material";
import SignupComplete from "./signUpComplete";
import createAxiosInstance from "../api/axiosConfig";

interface Info {
  email: string;
  password: string;
  gender: string;
  firstName: string;
  lastName: string;
  phone: string;
  birth: string;
  unit: string;
  number: string;
  address: string;
  city: string;
  country: string;
  notify: string;
  marketing: string;
  provider: string;
}
const Signup = () => {
  const { api, token } = User();
  const axiosInstance = createAxiosInstance(api, token);
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [info, setInfo] = useState<Info>({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phone: "",
    birth: "",
    gender: "",
    unit: "",
    number: "",
    address: "",
    city: "",
    country: "",
    notify: "Y",
    marketing: "Y",
    provider: "",
  });
  const [social, setSocial] = useState(false);
  const [agree, setAgree] = useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleData = (key: string, value: string) => {
    if (key === "number" || key === "phone") {
      setInfo((prev) => ({ ...prev, [key]: NumberOnly(value) }));
    } else {
      setInfo((prev) => ({ ...prev, [key]: value }));
    }
  };

  const confirm = document.getElementById("confirm") as HTMLInputElement;

  useEffect(() => {
    if (token) {
      setTimeout(() => {
        navigate("/welcome", { replace: true });
      }, 2000);
    }
  }, [token]);

  useEffect(() => {
    const socialCheck = sessionStorage.getItem("social");
    if (socialCheck) {
      const parse = JSON.parse(socialCheck);
      setSocial(true);
      setInfo((prev) => ({
        ...prev,
        ...parse,
      }));
      setTimeout(() => sessionStorage.clear(), 50);
    }
  }, []);

  /** sign check */
  const checkout = () => {
    if (agree) {
      if (confirm.value === info.email) {
        if (info.password.length > 7 && info.password.length < 21) {
          if (!info.gender) {
            handleData("gender", "other");
          }
          if (info.firstName) {
            if (info.lastName) {
              if (info.phone) {
                if (info.birth) {
                  if (info.address) {
                    if (info.city) {
                      if (info.country) {
                        register();
                      } else {
                        Swal.fire({
                          title: "Select your country",
                          icon: "error",
                          allowOutsideClick: false,
                        });
                      }
                    } else {
                      Swal.fire({
                        title: "City is required",
                        icon: "error",
                        allowOutsideClick: false,
                      });
                    }
                  } else {
                    Swal.fire({
                      title: "Address is required",
                      icon: "error",
                      allowOutsideClick: false,
                    });
                  }
                } else {
                  Swal.fire({
                    title: "Date of birth is required",
                    icon: "error",
                    allowOutsideClick: false,
                  });
                }
              } else {
                Swal.fire({
                  title: "Phone number is required",
                  icon: "error",
                  allowOutsideClick: false,
                });
              }
            } else {
              Swal.fire({
                title: "Last name is required",
                icon: "error",
                allowOutsideClick: false,
              });
            }
          } else {
            Swal.fire({
              title: "First name is required",
              icon: "error",
              allowOutsideClick: false,
            });
          }
        } else {
          Swal.fire({
            title: "Password must be between 8 ~ 20 characters",
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } else {
        Swal.fire({
          title: "Email and Confirm Email do not match",
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } else {
      Swal.fire({
        title: "You must agree to the terms and conditions",
        icon: "error",
        allowOutsideClick: false,
      });
    }
  };

  const register = () => {
    axiosInstance
      .post(`/register`, info)
      .then(({ data }) => {
        if (data.message === "success") {
          navigate(`/welcome`);
        }
      })
      .catch(({ response }) => {
        Swal.fire({
          title: response.data.message,
          icon: "error",
          allowOutsideClick: false,
        });
      });
  };

  const googleLogin = useGoogleLogin({
    scope: "email profile",
    flow: "auth-code",
    ux_mode: "redirect",
    redirect_uri: `${location.origin}/auth/google`,
    state: location.href,
  });

  const isSocial = () => {
    if (info.email) {
      return info.email;
    } else {
      ("Email");
    }
  };

  const socialCheckout = () => {
    if (agree) {
      if (!info.gender) {
        handleData("gender", "other");
      }
      if (info.firstName) {
        if (info.lastName) {
          if (info.phone) {
            if (info.birth) {
              if (info.address) {
                if (info.city) {
                  if (info.country) {
                    socialRegister();
                  } else {
                    Swal.fire({
                      title: "Select your country",
                      icon: "error",
                      allowOutsideClick: false,
                    });
                  }
                } else {
                  Swal.fire({
                    title: "City is required",
                    icon: "error",
                    allowOutsideClick: false,
                  });
                }
              } else {
                Swal.fire({
                  title: "Address is required",
                  icon: "error",
                  allowOutsideClick: false,
                });
              }
            } else {
              Swal.fire({
                title: "Date of birth is required",
                icon: "error",
                allowOutsideClick: false,
              });
            }
          } else {
            Swal.fire({
              title: "Phone number is required",
              icon: "error",
              allowOutsideClick: false,
            });
          }
        } else {
          Swal.fire({
            title: "Last name is required",
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } else {
        Swal.fire({
          title: "First name is required",
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } else {
      Swal.fire({
        title: "You must agree to the terms and conditions",
        icon: "error",
        allowOutsideClick: false,
      });
    }
  };

  const socialRegister = () => {
    axiosInstance
      .post(`/register`, info)
      .then(({ data }) => {
        if (data.message === "success") {
          navigate(`/welcome`);
        }
      })
      .catch(({ response }) => {
        Swal.fire({
          title: response.data.message,
          icon: "error",
          allowOutsideClick: false,
        });
      });
  };

  return (
    <>
      {location.pathname === "/signup" ? (
        <div className="signup-wrap">
          <div className="sub-top-area">
            <h3 className="tit">Welcome to the Lottery Cluster!</h3>
            {/* <p className="sub-tit">Sign up with your email to get started!</p> */}
          </div>

          <div className="sns-area">
            <h4>Signup with</h4>
            <div className="sns-wrap">
              {(info.provider === "" || info.provider === "google") && (
                <img
                  src={Google}
                  alt="Google login"
                  className="btn"
                  onClick={() => {
                    googleLogin();
                  }}
                />
              )}

              {(info.provider === "" || info.provider === "facebook") && (
                <img src={Facebook} className="btn" alt="Facebook login" />
              )}
              {(info.provider === "" || info.provider === "apple") && (
                <img src={Apple} className="btn" alt="Apple login" />
              )}
            </div>
          </div>
          {info.provider === "" && <p className="or">or</p>}
          <form>
            <div className="input-wrap">
              <TextField
                type="email"
                label="email"
                sx={{ width: "100%" }}
                onChange={({ target }) => handleData("email", target.value)}
                disabled={social}
                value={isSocial()}
              />
            </div>
            <div className="input-wrap">
              <TextField
                id="confirm"
                type="email"
                label="Confirm email"
                fullWidth
              />
            </div>

            <FormControl sx={{ width: 1 }}>
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                onChange={({ target }) => handleData("password", target.value)}
                id="password"
                name="pw"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <span className="material-symbols-rounded">
                        visibility
                      </span>
                    ) : (
                      <span className="material-symbols-rounded">
                        visibility_off
                      </span>
                    )}
                  </IconButton>
                }
                label="Password"
              />
            </FormControl>

            <RadioGroup
              row
              name="row-radio-buttons-group"
              value={info.gender}
              onChange={({ target }) => handleData("gender", target.value)}
            >
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>

            <div className="input-wrap">
              <TextField
                label="First Name"
                value={info.firstName}
                onChange={({ target }) => handleData("firstName", target.value)}
                fullWidth
              />
              <TextField
                label="Last Name"
                onChange={({ target }) => handleData("lastName", target.value)}
                value={info.lastName}
                fullWidth
              />
            </div>
            <div className="input-wrap">
              <TextField
                type="tel"
                label="Phone Number"
                fullWidth
                value={info.phone}
                onChange={({ target }) => handleData("phone", target.value)}
                inputProps={{
                  maxLength: 10,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </div>
            <div className="input-label-wrap">
              <label htmlFor="">Date of Birth</label>
              <div className="input-wrap">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    slotProps={{ textField: { fullWidth: true } }}
                    onChange={(e: Dayjs | null) => {
                      if (e) handleData("birth", e.format("YYYYMMDD"));
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="input-label-wrap">
              <label htmlFor="">Address</label>
              <div className="input-wrap">
                <TextField
                  label="Unit/Flat"
                  fullWidth
                  value={info.unit}
                  onChange={({ target }) => handleData("unit", target.value)}
                />
                <TextField
                  type="tel"
                  label="Street Number"
                  fullWidth
                  value={info.number}
                  onChange={({ target }) => handleData("number", target.value)}
                />
              </div>
              <div className="input-wrap">
                <TextField
                  label="Address"
                  fullWidth
                  value={info.address}
                  onChange={({ target }) => handleData("address", target.value)}
                />
              </div>
              <div className="input-wrap">
                <TextField
                  label="City / Town or Suburb"
                  fullWidth
                  value={info.city}
                  onChange={({ target }) => handleData("city", target.value)}
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Country
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Country"
                    value={info.country}
                    onChange={({ target }) =>
                      handleData("country", target.value)
                    }
                  >
                    <MenuItem value="AU">Australia</MenuItem>
                    <MenuItem value="NZ">New Zealand</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="input-label-wrap">
              <label htmlFor="">Notifications</label>
              <div className="input-wrap">
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={({ target }) =>
                        handleData("notify", target.checked ? "Y" : "N")
                      }
                    />
                  }
                  label="SMS notifications"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={({ target }) =>
                        handleData("marketing", target.checked ? "Y" : "N")
                      }
                    />
                  }
                  label="App Push"
                />
              </div>
            </div>
            <div className="input-wrap agree">
              <FormControlLabel
                sx={{ alignItems: "flex-start" }}
                value=""
                control={
                  <Checkbox
                    value={agree}
                    onChange={({ target }) => setAgree(target.checked)}
                    sx={{
                      marginTop: -1,
                    }}
                  />
                }
                label={
                  <p>
                    I have read The Lottery Cluster’s{" "}
                    <a href="/privacy" target="_blank">
                      privacy policy
                    </a>{" "}
                    and agree to the{" "}
                    <a href="/terms" target="_blank">
                      terms and conditions
                    </a>
                    .
                  </p>
                }
              />
            </div>
            <div
              className="btn style01 md spc"
              style={{ width: "100%" }}
              onClick={() => {
                if (social) {
                  socialCheckout();
                } else {
                  checkout();
                }
              }}
            >
              <span>Create account Now</span>
            </div>
          </form>
        </div>
      ) : location.pathname === "/welcome" ? (
        <SignupComplete info={info} />
      ) : (
        <></>
      )}
    </>
  );
};
export default Signup;
