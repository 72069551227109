// tools
import { useState } from "react";

// ui
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";

// constants
const steps = ["Pick Quick Play", "Checkout", "Review your purchase"];

// img
import MegaQuick01 from "../../img/sub/mega-quick-img01.png";
import MegaQuick02 from "../../img/sub/mega-quick-img02.png";
import MegaQuick03 from "../../img/sub/mega-quick-img03.png";
import PowerQuick01 from "../../img/sub/power-quick-img01.png";
import PowerQuick02 from "../../img/sub/power-quick-img02.png";
import PowerQuick03 from "../../img/sub/power-quick-img03.png";

interface HowProps {
  type: string;
}

const Quick = ({ type }: HowProps) => {
  const [activeStep, setActiveStep] = useState(0);

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep = isLastStep()
      ? steps.findIndex((_, i) => !i)
      : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  return (
    <>
      <div className="stepper-area">
        <Stepper
          nonLinear
          activeStep={activeStep}
          className="stepper"
          sx={{ width: "50%" }}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div className="btn-wrap">
          <div
            className={`btn style01 sm ${activeStep === 0 ? "disabled" : ""}`}
            color="inherit"
            onClick={() => {
              activeStep !== 0 ? handleBack() : null;
            }}
          >
            Back
          </div>

          <div className="btn style01 sm" onClick={handleNext}>
            Next
          </div>
        </div>
      </div>

      {activeStep === 0 && (
        <div className="step01 flex">
          <div className="txt-wrap">
            <h4 className="f24">
              <span>{activeStep + 1}</span>
              {steps[activeStep]}
            </h4>
            <p className="f16">
              Each game played costs $2.00.
              <br />
              Press the button to add quick play games
            </p>
          </div>
          <img
            src={type === "power" ? PowerQuick01 : MegaQuick01}
            alt="Quick Play Step 01"
          />
        </div>
      )}

      {activeStep === 1 && (
        <div className="step02">
          <div className="txt-wrap">
            <h4 className="f24">
              <span>{activeStep + 1}</span>
              {steps[activeStep]}
            </h4>
            <p className="f16">
              You can check the numbers that has been selected by Quick Play.
              <br />
              If you don't like the numbers you got, you can reroll the game
              <br />
              by clicking reselect button.
              <br />
              To Delete a line click on the trash icon next to the line.
            </p>
          </div>
          <img
            src={type === "power" ? PowerQuick02 : MegaQuick02}
            alt="Quick Play Step 01"
          />
        </div>
      )}
      {activeStep === 2 && (
        <div className="step03">
          <div className="txt-wrap">
            <h4 className="f24">
              <span>{activeStep + 1}</span>
              {steps[activeStep]}
            </h4>
            <p className="f16">
              Review your ticket summary, draw dates, total amount.
            </p>
          </div>
          <img
            src={type === "power" ? PowerQuick03 : MegaQuick03}
            alt="Quick Play Step 01"
          />
        </div>
      )}
    </>
  );
};

export default Quick;
