// tools
import { useEffect, useRef, useState } from "react";
import User from "../../tools/userInfo";
import { useLotto } from "../../App";

interface SectionType {
  type: string;
  original: string;
  loading: boolean;
}

interface Result {
  date: string;
  numbers: string;
}

const BallSection = ({ type, original, loading }: SectionType) => {
  const { api } = User();
  const [open, setOpen] = useState(false);
  const contRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLDivElement>(null);
  const { lottery } = useLotto();

  // Result drop down off
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        contRef.current &&
        !contRef.current.contains(event.target as Node) &&
        btnRef.current &&
        !btnRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [main, setMain] = useState<Result>({
    date: "",
    numbers: "",
  });

  useEffect(() => {
    const matchedLotteries = lottery.filter((lotto) => lotto.type === original);
    matchedLotteries.forEach((lotto) => {
      setMain({
        date: lotto.latestDrawTime.split(" ")[0],
        numbers: lotto.latestNumber || "No numbers available",
      });
    });
  }, []);

  return (
    <div className="result-area">
      <div className="tit-wrap">
        <p className="tit">
          {type === "power" ? "USA Power Lottery" : "USA Mega Lottery"}
          <span>&nbsp; Latest Results</span>
        </p>
        <div
          className={`btn ${open ? "active" : ""}`}
          onClick={() => (window.location.href = `/result2?type=${type}`)}
          ref={btnRef}
        >
          View results
          <span className="material-symbols-rounded">chevron_right</span>
        </div>
      </div>

      <div className="result-wrap">
        {/* 수정 */}
        <p className="date">{new Date(main.date).toDateString()}</p>
        <div className="ball-wrap">
          {main.numbers.split(",").map((num, index) => (
            <div
              className={`ball ${
                index === main.numbers.split(",").length - 1 ? type : ""
              }`}
              key={`${main.date}_${num}`}
            >
              {num}
            </div>
          ))}
        </div>
      </div>

      {/* View results */}
    </div>
  );
};

export default BallSection;
