import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MyBar: React.FC = () => {
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],

    datasets: [
      {
        label: "Mega ball",
        data: [2, 2, 5, 4, 3, 5, 4, 2, 1, 0, 3, 3],
        backgroundColor: "#ffde00",
        borderColor: "#ffde00",
        borderWidth: 1,
      },
      {
        label: "Power ball",
        data: [1, 2, 4, 5, 3, 2, 1, 4, 5, 1, 0, 1],
        backgroundColor: "#da3209",
        borderColor: "#da3209",
        borderWidth: 1,
      },
      {
        label: "Total game",
        data: [3, 4, 9, 9, 6, 7, 5, 6, 6, 1, 3, 4],
        backgroundColor: "#915eff",
        borderColor: "#915eff",
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Correct Balls",
        },
      },
      x: {
        title: {
          display: true,
          text: "Month",
        },
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "My Correct Counts",
      },
    },
  };

  return (
    <div className="bar-chart">
      <Bar data={data} options={options} />
    </div>
  );
};

export default MyBar;
