import { Dispatch, SetStateAction, useRef, useState } from "react";
import { FavData, FavMenuInfo } from "../../pages/favorites";
import FavTicket from "./favTicket";

//img
import Mega from "../../img/icon/mega-icon.svg";
import Power from "../../img/icon/power-icon.svg";
import Swal from "sweetalert2";
import { customAlphabet } from "nanoid";
import axios from "axios";
import User from "../../tools/userInfo";
import { update } from "../../tools/zustandUpdate";
import { useLotto } from "../../App";
import createAxiosInstance from "../../api/axiosConfig";

interface FavItemProps {
  key: number;
  idx: number;
  type: string;
  data: FavData;
  removeFav: (idx: string) => void;
  getFav: () => void;
  setFavList: Dispatch<SetStateAction<FavData[] | undefined>>;
  compare: FavData[] | undefined;
  selectMenu: FavMenuInfo[] | undefined;
  activeEdit: number | null;
  setActiveEdit: Dispatch<SetStateAction<number | null>>;
}

const FavItem = ({
  key,
  idx,
  type,
  data,
  removeFav,
  getFav,
  setFavList,
  compare,
  selectMenu,
  activeEdit,
  setActiveEdit,
}: FavItemProps) => {
  // tools
  const { api, token, info, setTotalInfo } = User();
  const { limitCart } = useLotto();
  const axiosInstance = createAxiosInstance(api, token);

  const foundItem = selectMenu?.filter((it) => it.code === data.systemCode)[0];
  const checkIsQty = foundItem ? foundItem.games : undefined;

  const isType = location.pathname.split("/")[1];

  const isImg = (type: string) => {
    if (type === "mega") {
      return Mega;
    } else {
      return Power;
    }
  };

  // func
  const handleEditAction = (action: Function) => {
    if (activeEdit !== null && activeEdit !== data.idx) {
      Swal.fire({
        icon: "warning",
        title: "Another card is being edited.",
        text: "Please save it before proceeding.",
        confirmButtonColor: "#af7af1",
        cancelButtonColor: "#876aab",
      });
      return;
    }
    setActiveEdit(data.idx);
    action();
  };

  /** update */
  const updateInfo = () => {
    update(api, token, setTotalInfo);
  };

  /** Ticket Delete */
  const delTicket = (targetIdx: number, cardIdx: number, code: string) => {
    const delList = data.nums.filter((it) => it.idx !== targetIdx);

    handleEditAction(() => {
      setFavList((prev: FavData[] | undefined) =>
        prev?.map((it: FavData) =>
          it.idx === cardIdx
            ? {
                ...it,
                ticketQty:
                  code === "CHN" || code === "QPK"
                    ? it.ticketQty - 1
                    : it.ticketQty - (checkIsQty ?? 1),
                nums: delList,
              }
            : it
        )
      );
      setIsSaved(data.idx);
    });
  };

  /** Ticket change check */
  const setIsSaved = (targetIdx: number) => {
    setFavList((prev) =>
      prev?.map((it) => (it.idx === targetIdx ? { ...it, isSaved: "N" } : it))
    );
  };

  /** Ticket Game add + */
  const addGame = (code: string) => {
    const sysCnt = code.includes("ST") && code.slice(-1);
    const fillNum = sysCnt === "X" ? 11 : Number(sysCnt) + 1;
    const randomKeyNum = customAlphabet("1234567890", 12);

    handleEditAction(() => {
      const newNums =
        code === "GUA"
          ? "0,0,0,0,0,G"
          : code === "PIC"
          ? "0,0,0,0,G,0"
          : code === "CHN" || code === "QPK"
          ? "0,0,0,0,0,0"
          : code.includes("ST")
          ? Array(fillNum).fill("0").join(",")
          : "";

      const newGame = {
        idx: Number(randomKeyNum()),
        method: "M",
        num: newNums,
      };

      setFavList((prev) =>
        prev?.map((it) =>
          it.idx === data.idx
            ? {
                ...it,
                isSaved: "N",
                ticketQty:
                  code === "CHN" || code === "QPK"
                    ? it.ticketQty + 1
                    : it.ticketQty + (checkIsQty ?? 1),
                nums: [...it.nums, newGame],
              }
            : it
        )
      );
    });
  };

  /** Fav APIs */
  // Update to Fav
  const updateFav = (target: number) => {
    const arrayNum = data.nums.map((item) => ({
      ...item,
      num: item.num.split(","),
    }));

    const updateData = {
      nums: arrayNum,
    };

    axiosInstance
      .put(`/favorite/${target}`, updateData)
      .then((res) => getFav())
      .catch((error) => console.error(error));
  };

  // Add to Cart
  const addCart = () => {
    const cartData = {
      productCode: data.productCode,
      methodCode: data.methodCode,
      systemCode: data.systemCode,
      multiplier: "N",
      draws: 1,
      ticketQty: data.ticketQty,
      subscription: "N",
      totalAmount: data.ticketQty * 7,
      nums: data.nums.map((item) => ({
        ...item,
        num: item.num.split(",").map((n) => n.trim()),
      })),
    };

    const arrayData = [];
    arrayData.push(cartData);

    axiosInstance
      .post(`/cart`, arrayData)
      .then((res) => updateInfo())
      .catch((error) => console.error(error));
  };

  // Add to Fav
  const addFav = () => {
    const arrayNum = data.nums.map((item) => ({
      ...item,
      num: item.num.split(","),
    }));

    const newData = {
      productCode: data.productCode,
      methodCode: data.methodCode,
      systemCode: data.systemCode,
      ticketQty: data.ticketQty,
      nums: arrayNum,
    };

    axiosInstance.post(`/favorite`, newData).then((res) => getFav());
  };

  const addSaveCart = (key: number) => {
    const isInFav = compare?.filter((it) => it.idx === data.idx) ? true : false;

    const hasIncompleteGame = data.nums.some((it) =>
      it.num.split(",").some((n) => n === "0")
    );

    handleEditAction(() => {
      if (hasIncompleteGame) {
        Swal.fire({
          icon: "warning",
          title: "There is an incomplete game.",
          allowOutsideClick: false,
          timer: 1000,
          confirmButtonColor: "#af7af1",
          cancelButtonColor: "#876aab",
        });
      } else if (info.cart >= limitCart) {
        Swal.fire({
          icon: "warning",
          title: `You cannot purchase more than ${limitCart} games in the cart.`,
          confirmButtonColor: "#af7af1",
          cancelButtonColor: "#876aab",
        });
      } else if (
        data.isSaved === "N" &&
        isInFav &&
        !data.isNew &&
        activeEdit === data.idx
      ) {
        // Update to Fav
        updateFav(data.idx);
      } else if (data.isSaved === "Y") {
        // Add to Cart
        addCart();
        Swal.fire({
          icon: "success",
          title: "Added to the shopping cart.",
          confirmButtonColor: "#af7af1",
          cancelButtonColor: "#876aab",
          timer: 1000,
        });
      } else if (data.isSaved === "N" && data.isNew) {
        // Save Fav and Cart
        addFav();
        addCart();
        Swal.fire({
          icon: "success",
          title: "Saved in Fav & Add to Cart",
          confirmButtonColor: "#af7af1",
          cancelButtonColor: "#876aab",
          timer: 1000,
        });
      }
      setActiveEdit(null);
    });
  };

  const isTitle = (text: string) => {
    if (text === "QPK" || text === "CHN") {
      return "Standard";
    } else if (text === "GUA") {
      return "Guaranteed";
    } else if (text === "PIC") {
      return "Pick 1 Less";
    } else if (text.includes("ST")) {
      const sysCnt = text.includes("ST") && text.slice(-1);
      const sysNum = sysCnt === "X" ? "10" : sysCnt;
      return `System ${sysNum}`;
    }
  };

  return (
    <div className={`fav-wrap ${activeEdit === data.idx && "editing"}`}>
      <div className="tit-wrap">
        <h4 className="tit">
          #{idx + 1} <img src={isImg(isType)} alt="Mega Millions" /> - &nbsp;
          {isTitle(data.systemCode)}
          {data.isSaved === "N" && <p className="tag editing">Editing</p>}
        </h4>

        <div
          className="btn material-symbols-rounded"
          onClick={() => removeFav(String(data.idx))}
        >
          delete
        </div>
      </div>

      <div className="ticket-wrap">
        {data.nums.map((it, idx) => (
          <FavTicket
            key={it.idx}
            idx={idx}
            target={it.idx}
            type={type}
            ticketNums={it}
            cardData={data}
            delTicket={delTicket}
            setIsSaved={setIsSaved}
            setFavList={setFavList}
            handleEditAction={handleEditAction}
          />
        ))}
      </div>
      <div className="btn-wrap">
        <div
          className="btn style01 md"
          onClick={() => addGame(data.systemCode)}
        >
          <span className="material-symbols-rounded">add</span>Add a game
        </div>
        {data.isSaved === "Y" && !data.isNew && (
          <div className="btn style01 md spc" onClick={() => addSaveCart(key)}>
            <span className="material-symbols-rounded">shopping_cart</span>
            Add to cart
          </div>
        )}
        {data.isSaved === "N" && !data.isNew && (
          <div className="btn style01 md" onClick={() => addSaveCart(key)}>
            Save
          </div>
        )}
        {data.isNew && data.isSaved === "N" && (
          <div className="btn style01 md" onClick={() => addSaveCart(key)}>
            Save and Add to Cart
          </div>
        )}
      </div>
    </div>
  );
};

export default FavItem;
