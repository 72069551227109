import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { reset, update } from "../../tools/zustandUpdate";
import axios from "axios";
import Swal from "sweetalert2";
import User from "../../tools/userInfo";

import { FaBowlingBall } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import FavTestComp from "./favTestComp";

// type
export interface Ball {
  white: number;
  special: number;
}

export interface BallSet {
  [key: string]: Ball;
  mega: Ball;
  power: Ball;
}

interface Nums {
  idx: number;
  num: string;
}

interface GameInfo {
  idx: number;
  methodCode: string;
  systemCode: string;
  productCode: string;
  ticketQty: number;
  nums: Nums[];
}

const FavoritesTest = () => {
  // tools
  const { api, token, info, setTotalInfo } = User();
  // stat
  const [test, setTest] = useState<GameInfo[]>();

  /** get Fav */
  const getFav = () => {
    axios
      .get(`${api}/favorite`, {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          Authorization: "bearer " + token,
        },
      })
      .then((res) => setTest(res.data.data))
      .catch((error) => console.error(error));
  };

  /** remove Fav */
  const removeFav = (idx: number) => {
    axios
      .delete(`${api}/favorite/${idx}`, {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          Authorization: "bearer " + token,
        },
      })
      .then((res) => {
        console.log(res);
        getFav();
      })
      .catch((error) => console.error(error));
  };

  const testEditAPi = () => {
    const data = {
      nums: [
        {
          idx: 84,
          num: ["2", "14", "48", "60", "68", "17"],
          method: "A",
        },
      ],
    };

    axios
      .put(`${api}/favorite/59`, data, {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          Authorization: "bearer " + token,
        },
      })
      .then((res) => console.log(res));
  };

  // useEffect
  useEffect(() => {
    if (!token) {
      Swal.fire({
        icon: "warning",
        title: "Members Only",
        text: "This method is only for Lottery Cluster members",
        allowOutsideClick: false,
      });
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    } else {
      getFav();
    }
  }, []);

  return (
    <div className="sub fav space-y-3">
      <h1>FavoritesTest</h1>
      <div className="border w-32 p-2" onClick={() => testEditAPi()}>
        Edit test button
      </div>
      {test?.map((it: any, idx) => (
        <FavTestComp key={idx} it={it} removeFav={removeFav} />
      ))}
    </div>
  );
};

export default FavoritesTest;
