interface HowProps {
  type: string
}

const Win = ({ type }: HowProps) => {
  return (
    <div className="tab-cont">
      <h4 className="f24">
        {type === 'power' ? 'Powerball' : 'Mega Millions'} Prizes & Odds
      </h4>
      <p className="f16">
        If your numbers match, you could be a winner! Reference the chart below
        to see the various game payouts and to understand how you could win more
        with {type === 'power' ? 'PowerBall' : 'Mega Millions'}®.
      </p>
    </div>
  )
}

export default Win
