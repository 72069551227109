import { useState, useEffect, useRef } from "react";

import User from "../../tools/userInfo";

import { GameMenuInfo } from "../../pages/stat";
import BarGame from "./barGame";
import Swal from "sweetalert2";
import { customAlphabet } from "nanoid";
import { RandomNumber } from "../../tools/random";
import createAxiosInstance from "../../api/axiosConfig";

interface Nums {
  idx: number;
  method: string;
  num: string;
}

interface GameData {
  idx: number;
  productCode: string;
  methodCode: string;
  systemCode: string;
  ticketQty: number;
  nums: Nums[];
}

interface BarChart {
  type: string;
  menu: string;
  month: string;
  tab: string;
  gameType: GameMenuInfo[];
}

interface Ball {
  number: number;
  count: number;
}

interface Balls {
  white: number;
  special: number;
}
interface BallSet {
  [key: string]: Balls;
  mega: Balls;
  power: Balls;
}

// constant
const BallSet: BallSet = {
  mega: {
    white: 70,
    special: 25,
  },
  power: {
    white: 69,
    special: 26,
  },
};

interface Data {
  total: number;
  white: Ball[];
  special: Ball[];
}

const Bar = ({ type, menu, month, tab, gameType }: BarChart) => {
  const { api } = User();

  const axiosInstance = createAxiosInstance(api, "");
  const BallType = type === "power" ? "Power Ball" : "Mega Ball";

  // for game
  const [selectWball, setSelectWball] = useState<string[] | undefined>();
  const [selectSball, setSelectSball] = useState<string | undefined | null>();

  const [selectMode, setSelectMode] = useState<GameMenuInfo | undefined>();
  const [selectedGame, setSelectedGame] = useState<GameMenuInfo | null>(null);
  const [gameList, setGameList] = useState<GameData[]>([]);
  const [isActive, setIsActive] = useState<number>(0);
  const [isSlide, setIsSlide] = useState<boolean>(false);
  const [isAuto, setIsAuto] = useState<boolean>(false);
  const [reset, setReset] = useState<string>("0,0,0,0,0,0");

  const wBallRule = selectMode?.regular;
  const sBallRule = selectMode?.special;
  const gameLength = gameList.length;

  const [list, setList] = useState<Data>({
    total: 0,
    white: [],
    special: [],
  });
  const [compareList, setCompareList] = useState<Data>({
    total: 0,
    white: [],
    special: [],
  });

  const quickPlay = (target: number) => {
    if (isAuto) return;

    const randomWball = RandomNumber(BallSet[type].white);
    const randomSball = RandomNumber(BallSet[type].special);

    const updatedGameList = gameList.map((it) => {
      if (it.idx === target) {
        return {
          ...it,
          nums: it.nums.map((numItem) => {
            return {
              ...numItem,
              method: "A",
            };
          }),
        };
      }
      return it;
    });

    setGameList(updatedGameList);

    const startQuick = () => {
      // White balls
      setSelectWball((prevWballs) => {
        if (prevWballs) {
          const checkPic = prevWballs.slice(0, 4);

          const allSelected = prevWballs.every(
            (ball) => ball !== "0" && ball !== "G"
          );

          // check "PIC" full wball
          if (selectMode?.code === "PIC" && !checkPic.includes("0")) {
            if (selectSball === "0") {
              return prevWballs;
            } else {
              const usedNumbers = new Set<string>();

              const newBalls = Array(4)
                .fill(null)
                .map(() => {
                  let newNumber;
                  do {
                    newNumber = RandomNumber(BallSet[type].white).toString();
                  } while (usedNumbers.has(newNumber));
                  usedNumbers.add(newNumber);
                  return newNumber;
                });

              newBalls.push("G");
              return newBalls.sort((a, b) =>
                a === "G" ? 1 : Number(a) - Number(b)
              );
            }
          }

          if (allSelected) {
            if (selectSball === "0") {
              return prevWballs;
            } else {
              const newWballs = prevWballs.map((ball) => {
                if (ball === "G") return "G";
                let newNumber;
                do {
                  newNumber = RandomNumber(BallSet[type].white).toString();
                } while (prevWballs.includes(newNumber) || newNumber === "G");
                return newNumber;
              });
              return newWballs.sort((a, b) => Number(a) - Number(b));
            }
          }

          // empty
          const newWballs = prevWballs.map((ball) => {
            if (ball === "0") {
              let newNumber;
              do {
                newNumber = RandomNumber(BallSet[type].white).toString();
              } while (prevWballs.includes(newNumber) || newNumber === "G");
              return newNumber;
            } else {
              return ball;
            }
          });
          return newWballs.sort((a, b) => Number(a) - Number(b));
        }
        return [randomWball.toString()];
      });

      // Special ball
      setSelectSball((prevSball) => {
        if (prevSball === "G") {
          return "G";
        } else if (selectWball?.includes("0") && selectSball !== "0") {
          return prevSball;
        } else if (!selectWball?.includes("0") && selectSball === "0") {
          return randomSball.toString();
        } else {
          return randomSball.toString();
        }
      });
    };

    const startQuickAuto = () => {
      // SP
      setSelectSball((prevSball) => {
        const randomSball = RandomNumber(BallSet[type].special).toString();

        if (prevSball === "G") {
          return "G";
        } else {
          return randomSball;
        }
      });

      setSelectWball((prevWballs) => {
        if (!prevWballs) return;

        // PIC
        if (selectMode?.code === "PIC") {
          const usedNumbers = new Set<string>();
          const newBalls = Array(4)
            .fill(null)
            .map(() => {
              let newNumber;
              do {
                newNumber = RandomNumber(BallSet[type].white).toString();
              } while (usedNumbers.has(newNumber));
              usedNumbers.add(newNumber);
              return newNumber;
            });

          newBalls.push("G");
          return newBalls.sort((a, b) =>
            a === "G" ? 1 : b === "G" ? -1 : Number(a) - Number(b)
          );
        }

        // ETC
        const newWballs = prevWballs.map((ball) => {
          if (ball === "G") return "G";
          let newNumber;
          do {
            newNumber = RandomNumber(BallSet[type].white).toString();
          } while (prevWballs.includes(newNumber) || newNumber === "G");
          return newNumber;
        });

        return newWballs.sort((a, b) => Number(a) - Number(b));
      });
    };

    const emptyWball = selectWball?.every((ball) => ball === "0");
    const fullWball = selectWball?.every((ball) => ball !== "0");
    const emptySball = selectSball === "0";
    const fullSball = selectSball !== "0";

    const allEmpty = emptySball && emptyWball;
    const allFull = fullSball && fullWball;
    const hasGInWball = selectWball?.[selectWball.length - 1] === "G";

    const isGUA = selectMode?.code === "GUA";
    const isPIC = selectMode?.code === "PIC";

    const fullGUA = fullWball && isGUA;
    const emptyGUA = emptyWball && isGUA;

    const picPatternWball =
      selectWball?.slice(0, 4).every((ball) => ball === "0") && hasGInWball;
    const picPatternSball = emptySball;

    const validPIC =
      isPIC && (allEmpty || allFull || (picPatternWball && picPatternSball));

    if (allEmpty || allFull || fullGUA || emptyGUA || validPIC) {
      setIsAuto(true);
      const playAuto = window.setInterval(() => {
        startQuickAuto();
      }, 100);

      setTimeout(() => {
        clearInterval(playAuto);
        setIsAuto(false);
      }, 1000);
    } else {
      startQuick();
    }
  };

  const handleDelete = (target: number) => {
    if (isAuto) return;

    const updateList = gameList.filter((it) => it.idx !== target);
    setGameList(updateList);
  };

  const handleClear = (target: number) => {
    if (isAuto) return;

    const resetNum = reset.split(",");
    const sBall = resetNum.pop();

    const updatedList = gameList.map((it) => {
      if (it.idx === target) {
        return {
          ...it,
          nums: it.nums.map((numObj) => ({
            ...numObj,
            num: reset,
          })),
        };
      }
      return it;
    });

    setGameList(updatedList);
    setSelectWball(resetNum);
    setSelectSball(sBall);
  };

  const handlePick = (number: string, isSpecial: boolean) => {
    if (!gameList[isActive]) return;

    if (isSpecial) {
      setSelectSball((prevSball) => (prevSball === number ? "0" : number));
    } else {
      setSelectWball((prevWballs) => {
        if (prevWballs) {
          const existingIndex = prevWballs.indexOf(number);
          if (existingIndex !== -1) {
            const updatedWballs = [...prevWballs];
            updatedWballs[existingIndex] = "0";
            return updatedWballs;
          }
          const zeroIndex = prevWballs.indexOf("0");
          if (zeroIndex !== -1) {
            const updatedWballs = [...prevWballs];
            updatedWballs[zeroIndex] = number;
            return updatedWballs.sort((a, b) => Number(a) - Number(b));
          }
          return prevWballs;
        }
        return [number];
      });
    }
  };

  useEffect(() => {
    if (wBallRule !== undefined) {
      if (selectMode?.code === "PIC") {
        const newWballs = Array(wBallRule).fill("0");
        newWballs.push("G");
        setSelectWball(newWballs);
      } else {
        const newWballs = Array(wBallRule).fill("0");
        setSelectWball(newWballs);
      }
    }

    if (sBallRule !== undefined) {
      const newSball = sBallRule === 1 ? "0" : "G";
      setSelectSball(newSball);
    }
  }, [wBallRule, sBallRule]);

  useEffect(() => {
    if (!gameList[isActive]) return;

    setGameList((prevGameList) => {
      const updatedGameList = [...prevGameList];
      const updatedNums = [...(selectWball || []), selectSball || "0"];

      updatedGameList[isActive].nums[0].num = updatedNums.join(",");

      return updatedGameList;
    });
  }, [selectWball, selectSball]);

  useEffect(() => {
    if (gameList[isActive]) {
      const balls = gameList[isActive].nums[0].num.split(",");
      const w = balls.slice(0, -1);
      const s = balls.pop();
      setSelectWball(w);
      setSelectSball(s);
    }
  }, [isActive]);

  const handleSelectChange = (selected: GameMenuInfo) => {
    Swal.fire({
      title: "This will reset the current games.",
      text: "Proceed with change?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        const randomKeyNum = customAlphabet("1234567890", 12);
        const newNums =
          selected.code === "GUA"
            ? "0,0,0,0,0,G"
            : selected.code === "PIC"
            ? "0,0,0,0,G,0"
            : selected.code === "CHN"
            ? "0,0,0,0,0,0"
            : selected.code.includes("ST")
            ? Array(selected.regular + selected.special)
                .fill("0")
                .join(",")
            : "";

        setReset(newNums);

        const game = {
          idx: Number(randomKeyNum()),
          productCode: type === "mega" ? "MM" : "PB",
          methodCode: selected.code === "CHN" ? "STD" : "SYS",
          systemCode: selected.code,
          ticketQty: selected.games,
          nums: [
            {
              idx: Number(randomKeyNum()),
              method: "M",
              num: newNums,
            },
          ],
        };

        setGameList([game]);
        setSelectedGame(selected);
        setSelectMode(selected);
      }
    });
  };

  useEffect(() => {
    const standardGameInfo = gameType.find((game) => game.name === "Standard");
    if (standardGameInfo) {
      setSelectedGame(standardGameInfo);
      setSelectMode(standardGameInfo);
      const randomKeyNum = customAlphabet("1234567890", 12);
      const standardGame = {
        idx: Number(randomKeyNum()),
        productCode: type === "mega" ? "MM" : "PB",
        methodCode: "STD",
        systemCode: "CHN",
        ticketQty: 1,
        nums: [
          {
            idx: Number(randomKeyNum()),
            method: "M",
            num: "0,0,0,0,0,0",
          },
        ],
      };
      setGameList([standardGame]);
    }
  }, [gameType]);

  const addGame = () => {
    if (isAuto) return;

    const isCheck = gameList.map((it) =>
      it.nums.map((numObj) => numObj.num.split(","))
    );
    const isUnComplete = isCheck.some((numsArray) =>
      numsArray.some((numList) => numList.includes("0"))
    );

    if (!selectedGame) return;

    if (isUnComplete) {
      setIsSlide(false);
      return;
    } else {
      setIsSlide(true);
      const randomKeyNum = customAlphabet("1234567890", 12);
      const newNums =
        selectedGame.code === "GUA"
          ? "0,0,0,0,0,G"
          : selectedGame.code === "PIC"
          ? "0,0,0,0,G,0"
          : selectedGame.code === "CHN"
          ? "0,0,0,0,0,0"
          : selectedGame.code.includes("ST")
          ? Array(selectedGame.regular + selectedGame.special)
              .fill("0")
              .join(",")
          : "";

      const game = {
        idx: Number(randomKeyNum()),
        productCode: type === "mega" ? "MM" : "PB",
        methodCode: selectedGame.code === "CHN" ? "STD" : "SYS",
        systemCode: selectedGame.code,
        ticketQty: selectedGame.games,
        nums: [
          {
            idx: Number(randomKeyNum()),
            method: "M",
            num: newNums,
          },
        ],
      };

      setGameList((prevList) => [...prevList, game]);
    }
  };

  // --- 통계 ----

  /** 날짜 선택 */
  function getAdjustedDateRange(option: string): string[] {
    const today = new Date();
    let adjustedDate = new Date(today);

    switch (option) {
      case "1":
        adjustedDate.setMonth(today.getMonth() - 1);
        break;
      case "2":
        adjustedDate.setMonth(today.getMonth() - 2);
        break;
      case "3":
        adjustedDate.setMonth(today.getMonth() - 3);
        break;
      case "4":
        adjustedDate.setMonth(today.getMonth() - 4);
        break;
      case "5":
        adjustedDate.setMonth(today.getMonth() - 5);
        break;
      case "12":
        adjustedDate.setFullYear(today.getFullYear() - 1);
        break;
      case "36":
        adjustedDate.setFullYear(today.getFullYear() - 3);
        break;
      case "60":
        adjustedDate.setFullYear(today.getFullYear() - 5);
        break;
      default:
        return [];
    }

    const formatDate = (date: Date) => date.toISOString().split("T")[0];
    return [formatDate(today), formatDate(adjustedDate)];
  }

  const selectedDates = getAdjustedDateRange(month);

  const selectCompare = (target: string) => {
    if (target === "frequency") {
      dataAxios();
    }
  };

  // Bar Data API
  const dataAxios = () => {
    const nowDate = selectedDates[0];
    const curDate = selectedDates[1];

    axiosInstance
      .get(
        `/number/${tab}?type=${
          type === "power" ? "pb" : "mm"
        }&sDate=${curDate}&eDate=${nowDate}`
      )
      .then(({ data }) => {
        const Data = data.data;
        if (menu === "frequency") {
          setList({
            total: Data.total_drawnum_all,
            white: Data.white_all,
            special: Data.power_all,
          });

          setCompareList({
            total: Data.total_drawnum_filtered,
            white: Data.white_filtered,
            special: Data.power_filtered,
          });
        } else {
          const white = [];
          const special = [];

          for (let i = 0; i < Data.white_all.length; i++) {
            white.push({
              number: Data.white_all[i].number,
              count: Data.white_all[i].timesince,
            });
          }

          for (let i = 0; i < Data.power_all.length; i++) {
            special.push({
              number: Data.power_all[i].number,
              count: Data.power_all[i].timesince,
            });
          }

          setList({
            total: Data.total_drawnum_all,
            white,
            special,
          });
        }
      });
  };

  useEffect(() => {
    dataAxios();
  }, [type, tab]);

  useEffect(() => {
    selectCompare(menu);
  }, [month]);

  return (
    <>
      <div className="bars">
        <div className="item">
          <div className="tit-wrap">
            <h4>White Ball</h4>
            {menu === "frequency" && (
              <div className="label">
                <div className="bar"></div>
                <span>Total</span>
                <div className="bar compare"></div>
                <span className="compare">{month} month</span>
              </div>
            )}
          </div>

          <table>
            <colgroup>
              <col width="56px" />
              <col width="" />
              <col width="92px" />
            </colgroup>
            <thead>
              <tr>
                <th>No.</th>
                <th>
                  {menu === "frequency"
                    ? "Frequency Graph"
                    : "Appearence Graph"}
                </th>
                <th>{menu === "frequency" ? "Action" : "Action"}</th>
              </tr>
            </thead>
            {/* white */}
            <tbody>
              {list.white && list.white.length > 0 ? (
                list.white.map((item) => {
                  const max = list.white.reduce((prev, value) =>
                    prev.count >= value.count ? prev : value
                  );

                  const maxCompare = compareList.white.reduce(
                    (prev, value) => (prev.count >= value.count ? prev : value),
                    { count: 0 }
                  );

                  const compareItem = compareList.white.find(
                    (compare) => compare.number === item.number
                  );

                  return (
                    <tr key={item.number}>
                      <td>
                        <div className="ball-wrap">
                          <div className="ball">{item.number}</div>
                        </div>
                      </td>
                      <td>
                        <div className="td-wrap">
                          {/*<meter
                        value={(item.count / max.count) * 100}
                        min="0"
                        max="100"
                      >
                        70%
                      </meter>*/}
                          <div
                            className="bar"
                            style={{
                              width: `${(item.count / max.count) * 100}%`,
                            }}
                          />
                          <p>{((item.count / list.total) * 100).toFixed(1)}%</p>
                        </div>
                        {/* 셀렉트버튼 추가로 수정 */}
                        <p className="times">
                          {menu === "frequency"
                            ? `${item.count} times`
                            : `${
                                item.count
                                  ? `Spotted ${item.count} draws ago`
                                  : "last draw"
                              }`}
                        </p>
                        {menu === "frequency" && (
                          <div className="td-wrap compare">
                            <div
                              className="bar"
                              style={{
                                width: `${
                                  (compareItem
                                    ? compareItem.count / maxCompare.count
                                    : 0) * 100
                                }%`,
                              }}
                            />
                            <p className="compare">
                              {`${(
                                (compareItem
                                  ? compareItem.count / compareList.total
                                  : 0) * 100
                              ).toFixed(1)}%`}
                            </p>
                          </div>
                        )}
                        {menu === "frequency" && (
                          <p className="times compare">
                            {compareItem
                              ? `${compareItem.count} times`
                              : "0 times"}
                          </p>
                        )}
                      </td>

                      <td>
                        <button
                          className={`btn style01 sm ${
                            selectWball?.includes(String(item.number)) &&
                            "white"
                          }`}
                          onClick={() =>
                            handlePick(item.number.toString(), false)
                          }
                        >
                          Select
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3}>No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="item">
          <div className="tit-wrap">
            <h4 className={type === "power" ? "power" : "mega"}>{BallType}</h4>
            {menu === "frequency" && (
              <div className="label">
                <div
                  className={type === "power" ? "power bar" : "mega bar"}
                ></div>
                <span className={type === "power" ? "power" : "mega"}>
                  Total
                </span>
                <div className="bar compare"></div>
                <span className="compare">{month} month</span>
              </div>
            )}
          </div>

          <table>
            <colgroup>
              <col width="56px" />
              <col width="" />
              <col width="92px" />
            </colgroup>
            <thead>
              <tr>
                <th>No.</th>
                <th>
                  {menu === "frequency"
                    ? "Frequency Graph"
                    : "Appearence Graph"}
                </th>
                <th>{menu === "frequency" ? "Action" : "Action"}</th>
              </tr>
            </thead>
            {/* special 데이터를 안전하게 map으로 렌더링 */}
            <tbody>
              {list.special && list.special.length > 0 ? (
                list.special.map((item) => {
                  const max = list.special.reduce((prev, value) =>
                    prev.count >= value.count ? prev : value
                  );

                  const maxCompare = compareList.special.reduce(
                    (prev, value) => (prev.count >= value.count ? prev : value),
                    { count: 0 }
                  );

                  const compareItem = compareList.special.find(
                    (compare) => compare.number === item.number
                  );

                  return (
                    <tr key={item.number}>
                      <td>
                        <div className="ball-wrap">
                          <div className={`ball ${type}`}>{item.number}</div>
                        </div>
                      </td>
                      <td>
                        <div className="td-wrap">
                          <div
                            className={
                              type === "power" ? "power bar" : "mega bar"
                            }
                            style={{
                              width: `${(item.count / max.count) * 100}%`,
                            }}
                          />
                          <p>{((item.count / list.total) * 100).toFixed(1)}%</p>
                        </div>
                        <p className="times">
                          {menu === "frequency"
                            ? `${item.count} times`
                            : `${
                                item.count
                                  ? `Spotted ${item.count} draws ago`
                                  : "last draw"
                              }`}
                        </p>
                        {menu === "frequency" && (
                          <div className="td-wrap compare">
                            <div
                              className={
                                type === "power" ? "power bar" : "mega bar"
                              }
                              style={{
                                width: `${
                                  (compareItem
                                    ? compareItem.count / maxCompare.count
                                    : 0) * 100
                                }%`,
                              }}
                            />
                            <p>
                              {`${(
                                (compareItem
                                  ? compareItem.count / compareList.total
                                  : 0) * 100
                              ).toFixed(1)}%`}
                            </p>
                          </div>
                        )}
                        {menu === "frequency" && (
                          <p className="times compare">
                            {compareItem
                              ? `${compareItem.count} times`
                              : "0 times"}
                          </p>
                        )}
                      </td>
                      <td>
                        {selectMode?.special !== 0 && (
                          <button
                            className={`btn style01 sm ${
                              selectSball === String(item.number) && "white"
                            }`}
                            onClick={() =>
                              handlePick(item.number.toString(), true)
                            }
                          >
                            Select
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3}>No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <BarGame
        type={type}
        gameType={gameType}
        selectedGame={selectedGame}
        gameList={gameList}
        isActive={isActive}
        isSlide={isSlide}
        gameLength={gameLength}
        isAuto={isAuto}
        handleSelectChange={handleSelectChange}
        addGame={addGame}
        setIsActive={setIsActive}
        handlePick={handlePick}
        handleDelete={handleDelete}
        handleClear={handleClear}
        quickPlay={quickPlay}
      />
    </>
  );
};

export default Bar;
