import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MyLine: React.FC = () => {
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Mega ball",
        data: [0, 2, 1, 3, 3, 4, 2, 3, 1, 1, 2, 1],
        fill: false,
        borderColor: "#4353ff",
        tension: 0.1,
      },
      {
        label: "Power ball",
        data: [1, 3, 5, 3, 1, 0, 4, 4, 3, 0, 2, 5],
        fill: false,
        borderColor: "#fe7554",
        tension: 0.1,
      },
      {
        label: "Total ball",
        data: [1, 5, 6, 6, 4, 4, 6, 7, 5, 1, 4, 6],
        fill: false,
        borderColor: "#915eff",
        tension: 0.1,
        hidden: true,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Corrected my pick of month",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Months",
        },
      },
      y: {
        title: {
          display: true,
          text: "Correct",
        },
        ticks: {
          stepSize: 1, // y축에서 1씩 증가
          min: 1, // y축 최소값
          max: 6, // y축 최대값
        },
      },
    },
  };

  return (
    <div className="line-chart">
      <Line data={data} options={options} />

      <div className="resp-table">
        <div className="item">
          <div className="th">Jan</div>
          <div className="td">correct : 0</div>
          <div className="td"></div>
        </div>

        <div className="item">
          <div className="th">Feb</div>
          <div className="td">correct : 1</div>
          <div className="td">
            <div className="ball-wrap">
              <div className="ball">1</div>
              <div className="ball mega">2</div>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="th">Mar</div>
          <div className="td">correct : 2</div>
          <div className="td">
            <div className="ball-wrap">
              <div className="ball">1</div>
              <div className="ball">1</div>
              <div className="ball mega">2</div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="th">Apr</div>
          <div className="td">correct : 2</div>
          <div className="td">
            <div className="ball-wrap">
              <div className="ball">1</div>
              <div className="ball">1</div>
              <div className="ball mega">2</div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="th">May</div>
          <div className="td">correct : 2</div>
          <div className="td">
            <div className="ball-wrap">
              <div className="ball">1</div>
              <div className="ball">1</div>
              <div className="ball mega">2</div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="th">June</div>
          <div className="td">correct : 2</div>
          <div className="td">
            <div className="ball-wrap">
              <div className="ball">1</div>
              <div className="ball">1</div>
              <div className="ball mega">2</div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="th">Jul</div>
          <div className="td">correct : 2</div>
          <div className="td">
            <div className="ball-wrap">
              <div className="ball">1</div>
              <div className="ball">1</div>
              <div className="ball mega">2</div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="th">Aug</div>
          <div className="td">correct : 2</div>
          <div className="td">
            <div className="ball-wrap">
              <div className="ball">1</div>
              <div className="ball">1</div>
              <div className="ball mega">2</div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="th">Sep</div>
          <div className="td">correct : 2</div>
          <div className="td">
            <div className="ball-wrap">
              <div className="ball">1</div>
              <div className="ball">1</div>
              <div className="ball mega">2</div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="th">Oct</div>
          <div className="td">correct : 2</div>
          <div className="td">
            <div className="ball-wrap">
              <div className="ball">1</div>
              <div className="ball">1</div>
              <div className="ball mega">2</div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="th">Nov</div>
          <div className="td">correct : 2</div>
          <div className="td">
            <div className="ball-wrap">
              <div className="ball">1</div>
              <div className="ball">1</div>
              <div className="ball mega">2</div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="th">Dec</div>
          <div className="td">correct : 2</div>
          <div className="td">
            <div className="ball-wrap">
              <div className="ball">1</div>
              <div className="ball">1</div>
              <div className="ball mega">2</div>
            </div>
          </div>
        </div>
      </div>

      {/* <ul>
        <li>
          <h3>February</h3>
          <p>correct : 1</p>
        </li>
        <li>
          <h3>February</h3>
          <p>correct : 2</p>
          <div className="ball-wrap">
            <div className="ball">1</div>
            <div className="ball mega">2</div>
          </div>
        </li>
        <li>
          <h3>March</h3>
          <p>correct : 1</p>
          <div className="ball-wrap">
            <div className="ball">1</div>
          </div>
        </li>
        <li>
          <h3>April</h3>
          <p>correct : 3</p>
        </li>
        <li>
          <h3>May</h3>
          <p>correct : 3</p>
        </li>
        <li>
          <h3>June</h3>
          <p>correct : 4</p>
        </li>
        <li>
          <h3>July</h3>
          <p>correct : 2</p>
        </li>
        <li>
          <h3>August</h3>
          <p>correct : 1</p>
        </li>
        <li>
          <h3>September</h3>
          <p>correct : 1</p>
        </li>
        <li>
          <h3>October</h3>
          <p>correct : 1</p>
        </li>
        <li>
          <h3>November</h3>
          <p>correct : 1</p>
        </li>
        <li>
          <h3>December</h3>
          <p>correct : 1</p>
        </li>
      </ul> */}
    </div>
  );
};

export default MyLine;
