// tools
import { useEffect } from "react";

// constants
import User from "../tools/userInfo";
import { useLotto } from "../App";

// ui
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Lottie from "lottie-react";

// comp
import Login from "../components/common/login";

// img
import Shine from "../img/sub/shine.json";
import {
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

interface Info {
  email: string;
  password: string;
  gender: string;
  firstName: string;
  lastName: string;
  phone: string;
  birth: string;
  unit: string;
  number: string;
  address: string;
  city: string;
  country: string;
  notify: string;
  marketing: string;
}

const dummy = {
  email: "test01@gtip.co.kr",
  phone: "123456789",
  birth: "20240502",
  gender: "male",
  unit: "123",
  number: "456",
  address: "Empire Circuit, Yarralumla",
  city: "ACT",
  country: "AU",
  provider: "cluster",
  level: 1,
  cash: "330.00",
  prize: "126.36",
  notify: "N",
  marketing: "Y",
  remaining: 69,
  firstName: "DM",
  lastName: "Lee",
  totalPrize: "166.14",
  weeklySpendLimit: 69,
  createdAt: "2024-02-29 14:57:07",
};

interface Props {
  info: Info;
}

const SignUpComplete = ({ info }: Props) => {
  const { open, setOpen } = useLotto();

  const { token } = User();

  useEffect(() => {
    if (token) {
      location.replace("/");
    }
  }, []);

  return (
    <div className="signup-wrap complete">
      <div className="sub-top-area">
        <Lottie animationData={Shine} className="signup-shine" loop={true} />
        <span className="material-symbols-rounded">check_circle</span>
        <h3 className="tit">Success !</h3>
        <p className="sub-tit">
          Congratulations! <br />
          Your Account has been created.
        </p>
      </div>

      <form>
        <h2>Your informations</h2>

        <div className="input-wrap">
          <TextField
            type="email"
            label={dummy.email}
            sx={{ width: "50%" }}
            disabled
          />
        </div>
        <div className="input-wrap">
          <RadioGroup row name="row-radio-buttons-group">
            <FormControlLabel
              value="male"
              control={<Radio />}
              label="Male"
              checked={dummy.gender === "male"}
              disabled
            />
            <FormControlLabel
              value="female"
              control={<Radio />}
              label="Female"
              checked={dummy.gender === "female"}
              disabled
            />
            <FormControlLabel
              value="other"
              control={<Radio />}
              label="Other"
              checked={dummy.gender === "other"}
              disabled
            />
          </RadioGroup>
        </div>

        <div className="input-wrap">
          <TextField label={dummy.firstName} fullWidth disabled />
          <TextField label={dummy.lastName} fullWidth disabled />
        </div>

        <div className="input-wrap">
          <TextField
            type="tel"
            label={dummy.phone}
            fullWidth
            disabled
            inputProps={{
              maxLength: 10,
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
          />
        </div>
        <div className="input-label-wrap">
          <label htmlFor="">Date of Birth</label>
          <div className="input-wrap">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                slotProps={{ textField: { fullWidth: true } }}
                defaultValue={dayjs(dummy.birth)}
                disabled
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="input-label-wrap">
          <label htmlFor="">Address</label>
          <div className="input-wrap">
            <TextField label={dummy.unit} fullWidth disabled />
            <TextField type="tel" label={dummy.number} fullWidth disabled />
          </div>
          <div className="input-wrap">
            <TextField label={dummy.address} fullWidth disabled />
          </div>
          <div className="input-wrap">
            <TextField label={dummy.city} fullWidth disabled />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {dummy.country === "AU" ? "Australia" : "New Zealand"}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Country"
                disabled
              >
                <MenuItem value="AU">Australia</MenuItem>
                <MenuItem value="NZ">New Zealand</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="input-label-wrap">
          <label htmlFor="">Notifications</label>
          <div className="input-wrap">
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="SMS notifications"
              checked={info.notify === "Y"}
              disabled
            />
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="App Push"
              checked={info.marketing === "Y"}
              disabled
            />
          </div>
        </div>
      </form>
      <div className="btn-wrap">
        <div className="btn style01 md" onClick={() => (location.href = "/")}>
          <span className="material-symbols-rounded">chevron_left</span>
          Home
        </div>
        <div className="btn style01 md" onClick={() => setOpen(true)}>
          Log In
          <span className="material-symbols-rounded">chevron_right</span>
        </div>
      </div>
      <Login open={open} setOpen={setOpen} />
    </div>
  );
};

export default SignUpComplete;
