const Article: React.FC<{ image: string; index: number }> = ({
  image,
  index,
}) => (
  <div className="item" key={index}>
    <div className="img-wrap">
      <img src={image} alt="Thumbnail" />
    </div>
    <div className="txt-wrap">
      <h4>Feature : The Most Affordable Rolex Watches</h4>
      <p>
        If you keep a close eye on watch prices like we do, you won't be
        surprised to learn how affordable some Rolexes have become, With many
        entry-level models costing less than they have for serve
      </p>
    </div>
  </div>
);

export default Article;
