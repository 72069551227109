import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Slider from "react-slick";
import { customAlphabet } from "nanoid";
import Swal from "sweetalert2";
import BarGameCard from "./barGameCard";
import User from "../../tools/userInfo";
import { useLotto } from "../../App";
import { update } from "../../tools/zustandUpdate";
import createAxiosInstance from "../../api/axiosConfig";

// type
interface Nums {
  idx: number;
  method: string;
  num: string;
}

interface GameData {
  idx: number;
  productCode: string;
  methodCode: string;
  systemCode: string;
  ticketQty: number;
  nums: Nums[];
}

interface GameMenuInfo {
  idx: number;
  name: string;
  code: string;
  description: string;
  regular: number;
  special: number;
  games: number;
  price: string;
  optionPrice: string;
}

interface RemoteProps {
  gameType: GameMenuInfo[] | null;
  selectedGame: GameMenuInfo | null;
  gameList: GameData[];
  type: string;
  isActive: number;
  isSlide: boolean;
  gameLength: number;
  isAuto: boolean;
  quickPlay: (target: number) => void;
  addGame: () => void;
  handleSelectChange: (selected: GameMenuInfo) => void;
  setIsActive: Dispatch<SetStateAction<number>>;
  handlePick: (number: string, isSpecial: boolean) => void;
  handleDelete: (target: number) => void;
  handleClear: (target: number) => void;
}

const BarGame = ({
  type,
  gameType,
  selectedGame,
  gameList,
  gameLength,
  isAuto,
  isActive,
  handleSelectChange,
  addGame,
  setIsActive,
  handlePick,
  handleDelete,
  handleClear,
  quickPlay,
}: RemoteProps) => {
  const { api, token, info, setTotalInfo } = User();
  const { lottery, limitCart, checkNoLoginCart, noLoginCart } = useLotto();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const sliderRef = useRef<Slider>(null);

  const axiosInstance = createAxiosInstance(api, token);

  // noLogin Cart
  const existingData = localStorage.getItem("noLgCart");
  const noLgCartArray = existingData ? JSON.parse(existingData) : [];

  // Slider Settings
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current: number) => {
      setIsActive(current);
    },
    afterChange: (current: number) => {
      setIsActive(current);
    },
  };

  const isType = () => {
    if (type === "mega") {
      return lottery[1];
    } else {
      return lottery[0];
    }
  };

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const calculateQty = (sysCode: string) => {
    const typeGua = type === "power" ? 26 : 25;
    const typePic = type === "power" ? 65 : 66;

    if (sysCode === "GUA") {
      return gameLength * typeGua;
    } else if (sysCode === "PIC") {
      return gameLength * typePic;
    } else if (sysCode === "ST6") {
      return gameLength * 6;
    } else if (sysCode === "ST7") {
      return gameLength * 21;
    } else if (sysCode === "ST8") {
      return gameLength * 56;
    } else if (sysCode === "ST9") {
      return gameLength * 126;
    } else if (sysCode === "STX") {
      return gameLength * 252;
    } else {
      return gameLength;
    }
  };

  const hasIncompleteGame = gameList.some((game) =>
    game.nums.some((numItem) =>
      numItem.num
        .split(",")
        .map((n) => n.trim())
        .includes("0")
    )
  );

  const updateCart = () => {
    update(api, token, setTotalInfo);
  };

  const addFav = () => {
    if (hasIncompleteGame) {
      Swal.fire({
        title: "There is an incomplete game.",
        icon: "warning",
        allowOutsideClick: false,
      });
    } else {
      const data = {
        productCode: gameList[0].productCode,
        methodCode: gameList[0].methodCode,
        systemCode: gameList[0].systemCode,
        ticketQty: calculateQty(gameList[0].systemCode),
        nums: gameList.flatMap((it) =>
          it.nums.map((it) => ({
            method: it.method,
            num: it.num.split(",").map((n) => n.trim()),
          }))
        ),
      };

      axiosInstance
        .post(`/favorite`, data)
        .then((res) =>
          Swal.fire({
            icon: "success",
            title: "Completed Add to Favorite!",
          })
        )
        .catch((error) => console.error(error));
    }
  };

  const addCart = () => {
    if (hasIncompleteGame) {
      Swal.fire({
        title: "There is an incomplete game.",
        icon: "warning",
        allowOutsideClick: false,
      });
    } else if (info.cart >= limitCart) {
      Swal.fire({
        icon: "warning",
        title: `You cannot purchase more than ${limitCart} games in the cart.`,
      });
    } else if (token.length === 0) {
      const randomKeyNum = customAlphabet("1234567890", 12);

      const data = {
        idx: Number(randomKeyNum()),
        productName:
          gameList[0].productCode === "MM"
            ? "US Mega Millions"
            : "US Powerball",
        productCode: gameList[0].productCode,
        methodCode: gameList[0].methodCode,
        systemCode: gameList[0].systemCode,
        multiplier: "N",
        subscription: "N",
        draws: 1,
        syndicateIdx: null,
        jackpot: isType().jackpot,
        playdate: isType().playdate,
        ticketQty: calculateQty(gameList[0].systemCode),
        totalAmount: calculateQty(gameList[0].systemCode) * 7,
        nums: gameList.flatMap((it) =>
          it.nums.map((it, idx) => ({
            method: it.method,
            num: it.num,
          }))
        ),
      };

      noLgCartArray.push(data);
      localStorage.setItem("noLgCart", JSON.stringify(noLgCartArray));

      Swal.fire({
        icon: "success",
        title: "Completed Add to Cart!",
        allowOutsideClick: false,
        didClose: () => {
          window.location.reload();
          checkNoLoginCart();
        },
      });
    } else {
      const data = {
        productCode: gameList[0].productCode,
        methodCode: gameList[0].methodCode,
        systemCode: gameList[0].systemCode,
        multiplier: "N",
        subscription: "N",
        draws: 1,
        totalAmount: calculateQty(gameList[0].systemCode) * 7,
        ticketQty: calculateQty(gameList[0].systemCode),
        nums: gameList.flatMap((it) =>
          it.nums.map((it) => ({
            method: it.method,
            num: it.num.split(",").map((n) => n.trim()),
          }))
        ),
      };

      const arrayData = [];
      arrayData.push(data);

      axiosInstance
        .post(`/cart`, arrayData)
        .then((res) => {
          updateCart();

          Swal.fire({
            icon: "success",
            title: "Completed Add to Cart!",
            didClose: () => window.location.reload(),
          });
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <div className="statGameWrap">
      <div className="sgInner">
        <div className="sgHead">
          <FormControl sx={{ minWidth: 144 }}>
            <InputLabel id="demo-select-small-label">Select mode</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              label="Select mode"
              value={selectedGame ? String(selectedGame.idx) : ""}
            >
              {gameType?.map((it) => (
                <MenuItem
                  key={it.idx}
                  value={String(it.idx)}
                  onClick={() => handleSelectChange(it)}
                >
                  {it.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {token.length > 0 && (
            <span
              className="btn material-symbols-rounded"
              onClick={() => addFav()}
            >
              favorite
            </span>
          )}
        </div>
        <div className="sgFoot">
          <div className="ballWrap">
            <div className="slider-container ticket-wrap">
              <Slider {...settings} ref={sliderRef}>
                {gameList.map((it, idx) => (
                  <BarGameCard
                    gameType={type}
                    key={idx}
                    idx={idx}
                    data={it}
                    isOpen={isOpen}
                    isAuto={isAuto}
                    isActive={isActive}
                    gameLength={gameLength}
                    setIsOpen={setIsOpen}
                    handlePick={handlePick}
                    handleDelete={handleDelete}
                    handleClear={handleClear}
                    quickPlay={quickPlay}
                  />
                ))}
              </Slider>
            </div>
          </div>
          <div className="btn-wrap">
            <div
              className="btn style01 md"
              onClick={() => {
                addGame();
                goToNextSlide();
              }}
            >
              <span className="material-symbols-rounded">add_circle</span>Add a
              game
            </div>
            <div className="btn style01 md spc" onClick={() => addCart()}>
              <span className="material-symbols-rounded">shopping_cart</span>Add
              to cart
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarGame;
